import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadProductTypes as loadProductTypesAction,
	loadProductTypeSuccess,
	loadProductTypesSuccess,
	addProductTypeSuccess,
	updateProductTypeSuccess,
    deleteProductTypeSuccess,
    deleteAllProductTypesSuccess
} from '../actions/ProductType';

import {
	PRODUCT_TYPES_LOAD,
	PRODUCT_TYPE_LOAD,
	PRODUCT_TYPE_ADD,
	PRODUCT_TYPE_UPDATE,
    PRODUCT_TYPE_DELETE,
    PRODUCT_TYPE_DELETE_ALL,
    PRODUCT_TYPE_DELETE_ALL_SUCCESS,
    PRODUCT_TYPE_DELETE_ALL_FAILED,
	PDF_LOAD,
	PDF_ADD,
	PDF_UPDATE,
	PDF_DELETE,
    PRODUCT_TYPE_ADD_SUCCESS,
    PRODUCT_TYPE_UPDATE_SUCCESS,
    PRODUCT_TYPE_RESTORE,
} from '../../constants/ActionTypes';

import {
    userSignOut
} from '../actions/Auth';

const loadProductTypesRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`product-types/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`product-types`, { params })
        .then(data => data)
        .catch(error => error);
}


const loadProductTypeRequest = async (id) =>
    await Api().get(`product-types/${id}`)
        .then(data => data)
        .catch(error => error);

const addProductTypeRequest = async (payload) =>
    await Api().post(`product-types`, payload)
      .then(data => data)
      .catch(error => error);

const updateProductTypeRequest = async (payload) =>
    await Api().patch(`product-types/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deleteProductTypeRequest = async (id) =>
    await Api().delete(`product-types/${id}`)
    .then(data => data)
    .catch(error => error);

const removeProductTypeRequest = async (id) =>
    await Api().delete(`product-types/remove/${id}`)
    .then(data => data)
    .catch(error => error);

const restoreProductTypeRequest = async (id) =>
    await Api().delete(`product-types/restore/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadProductTypes({payload}) {
    try {        
        const data = yield call(loadProductTypesRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProductTypesSuccess(data.data));        
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshProductTypes({payload}) {
    yield loadProductTypes(payload);
}

function* processLoadProductTypes() {
    yield takeEvery(PRODUCT_TYPES_LOAD, loadProductTypes);
}

function* loadProductType({selectedProductTypeId}) {
    try {
        const data = yield call(loadProductTypeRequest, selectedProductTypeId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProductTypeSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadProductType() {
    yield takeEvery(PRODUCT_TYPE_LOAD, loadProductType);
}


function* addProductType({payload}) {
    try {
        const data = yield call(addProductTypeRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProductTypesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddProductType() {
    yield takeEvery(PRODUCT_TYPE_ADD, addProductType);
}


function* updateProductType({payload}) {
    try {
        const data = yield call(updateProductTypeRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProductTypesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateProductType() {
    yield takeEvery(PRODUCT_TYPE_UPDATE, updateProductType);
}

function* deleteProductType({payload}) {
    try {
        let data = null;
        if (payload.isRemove) {
            data = yield call(removeProductTypeRequest, payload.model.id);
        } else {
            data = yield call(deleteProductTypeRequest, payload.model.id);
        }     
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProductTypesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllProductTypes({payload}) {
    try {
        for(let i = 0; i < payload.models.length; ++i){
            let data = yield call(deleteProductTypeRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }

        yield put(loadProductTypesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* restoreProductType({payload}) {
    try {
        let data = yield call(restoreProductTypeRequest, payload.model.id);             
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProductTypesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteProductType() {
    yield takeEvery(PRODUCT_TYPE_DELETE, deleteProductType);
}

function* processDeleteAllProductTypes() {
    yield takeEvery(PRODUCT_TYPE_DELETE_ALL, deleteAllProductTypes);
}

function* processRestoreProductType() {
    yield takeEvery(PRODUCT_TYPE_RESTORE, restoreProductType);
}

export default function* productTypeSagas() {
    yield all([fork(processLoadProductTypes),
      fork(processLoadProductType),
      fork(processAddProductType),
      fork(processUpdateProductType),
      fork(processDeleteAllProductTypes),
      fork(processDeleteProductType),
      fork(processRestoreProductType)
    ]);
}
