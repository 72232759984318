import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadPdfAlbums as loadPdfAlbumsAction,
	loadPdfAlbumSuccess,
	loadPdfAlbumsSuccess,
	addPdfAlbumSuccess,
	updatePdfAlbumSuccess,
    deletePdfAlbumSuccess,
    deleteAllPdfAlbumsSuccess,
	loadPdfSuccess,
	addPdfSuccess,
	updatePdfSuccess,
	deletePdfSuccess
} from '../actions/PdfAlbum';
import {
	loadPdfAlbumsFailed,
	loadPdfAlbumFailed,
	addPdfAlbumFailed,
	updatePdfAlbumFailed,
	deletePdfAlbumFailed,
	loadPdfFailed,
	addPdfFailed,
	updatePdfFailed,
	deletePdfFailed
} from '../actions/PdfAlbum';

import {
    userSignOut
} from '../actions/Auth';


import {
	PDFALBUMS_LOAD,
	PDFALBUM_LOAD,
	PDFALBUM_ADD,
	PDFALBUM_UPDATE,
    PDFALBUM_DELETE,
    PDFALBUM_DELETE_ALL
} from '../../constants/ActionTypes';

const loadPdfAlbumsRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`pdf-albums/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`pdf-albums`, { params })
        .then(data => data)
        .catch(error => error);
}
    

const loadPdfAlbumRequest = async (id) =>
    await Api().get(`pdf-albums/${id}`)
        .then(data => data)
        .catch(error => error);

const addPdfAlbumRequest = async (payload) =>
    await Api().post(`pdf-albums`, payload)
      .then(data => data)
      .catch(error => error);

const updatePdfAlbumRequest = async (payload) =>
    await Api().patch(`pdf-albums/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deletePdfAlbumRequest = async (id) =>
    await Api().delete(`pdf-albums/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadPdfAlbums({payload}) {
    try {
        const data = yield call(loadPdfAlbumsRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()):
        yield put(loadPdfAlbumsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
        console.log(error)
    }
}

function* refreshPdfAlbums({payload}) {
    yield loadPdfAlbums(payload);
}

function* processLoadPdfAlbums() {
    yield takeEvery(PDFALBUMS_LOAD, loadPdfAlbums);
}

function* loadPdfAlbum({selectedPdfAlbumId}) {
    try {
        const data = yield call(loadPdfAlbumRequest, selectedPdfAlbumId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadPdfAlbumSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadPdfAlbum() {
    yield takeEvery(PDFALBUM_LOAD, loadPdfAlbum);
}


function* addPdfAlbum({payload}) {
    try {
        const data = yield call(addPdfAlbumRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadPdfAlbumsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddPdfAlbum() {
    yield takeEvery(PDFALBUM_ADD, addPdfAlbum);
}


function* updatePdfAlbum({payload}) {
    try {
        const data = yield call(updatePdfAlbumRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadPdfAlbumsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdatePdfAlbum() {
    yield takeEvery(PDFALBUM_UPDATE, updatePdfAlbum);
}

function* deletePdfAlbum({payload}) {
    try {
        const data = yield call(deletePdfAlbumRequest, payload.model.id);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadPdfAlbumsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllPdfAlbums({payload}) {
    try {        
        for(let i = 0; i < payload.models.length; ++i){
            let data = yield call(deletePdfAlbumRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }
        
        yield put(loadPdfAlbumsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeletePdfAlbum() {
    yield takeEvery(PDFALBUM_DELETE, deletePdfAlbum);
}

function* processDeleteAllPdfAlbums() {
    yield takeEvery(PDFALBUM_DELETE_ALL, deleteAllPdfAlbums);
}

export default function* pdfAlbumSagas() {
    yield all([fork(processLoadPdfAlbums),
      fork(processLoadPdfAlbum),
      fork(processAddPdfAlbum),
      fork(processUpdatePdfAlbum),
      fork(processDeleteAllPdfAlbums),
      fork(processDeletePdfAlbum)]);
}