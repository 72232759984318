import {
	IMAGES_SELECT_ALL,
	IMAGES_UNSELECT_ALL,
	IMAGE_SELECTED,
	IMAGES_LOAD,
	IMAGES_LOAD_SUCCESS,
    IMAGES_LOAD_FAILED,
    IMAGE_LOAD,
	IMAGE_LOAD_SUCCESS,
	IMAGE_LOAD_FAILED,
	IMAGE_ADD,
	IMAGE_ADD_SUCCESS,
	IMAGE_ADD_FAILED,
	IMAGE_UPDATE,
	IMAGE_UPDATE_SUCCESS,
	IMAGE_UPDATE_FAILED,
	IMAGE_DELETE,
	IMAGE_DELETE_SUCCESS,
	IMAGE_DELETE_FAILED,
	IMAGE_DELETE_ALL,
	IMAGE_DELETE_ALL_SUCCESS,
	IMAGE_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

export const selectAllImages = () => {
	return {
		type: IMAGES_SELECT_ALL
	}
}

export const unselectAllImages = () => {
	return {
		type: IMAGES_UNSELECT_ALL
	}
}

export const selectImage = data => {
	return {
		type: IMAGE_SELECTED,
		payload: data
	}
}

export const loadImages = (data) => {
	return {
		type: IMAGES_LOAD,
		payload: data
	};
}


export const loadImagesSuccess = (data) => {
	return {
        type: IMAGES_LOAD_SUCCESS,
        payload: data
	};
}


export const loadImagesFailed = () => {
	return {
		type: IMAGES_LOAD_FAILED
	};
}


export const loadImage = () => {
	return {
		type: IMAGE_LOAD
	};
}


export const loadImageSuccess = (data) => {
	return {
		type: IMAGE_LOAD_SUCCESS,
		payload: data
	};
}


export const loadImageFailed = () => {
	return {
		type: IMAGE_LOAD_FAILED
	};
}


export const addImage = (data) => {
	return {
		type: IMAGE_ADD,
		payload: data
	};
}


export const addImageSuccess = (filter) => {
	return {
		type: IMAGE_ADD_SUCCESS,
		message: 'Image added successfully.',
		payload: filter
	};
}


export const addImageFailed = () => {
	return {
		type: IMAGE_ADD_FAILED
	};
}


export const updateImage = (data) => {
	return {
		type: IMAGE_UPDATE,
		payload: data
	};
}


export const updateImageSuccess = (data) => {
	return {
		type: IMAGE_UPDATE_SUCCESS,
		payload: data
	};
}


export const updateImageFailed = (data) => {
	return {
		type: IMAGE_UPDATE_FAILED,
		payload: data
	};
}


export const deleteImage = (data) => {
	return {
		type: IMAGE_DELETE,
		payload: data
	};
}


export const deleteImageSuccess = () => {
	return {
		type: IMAGE_DELETE_SUCCESS,
		message: 'Image deleted successfully.'
	};
}


export const deleteImageFailed = () => {
	return {
		type: IMAGE_DELETE_FAILED
	};
}

export const deleteAllImages = (data) => {
	return {
		type: IMAGE_DELETE_ALL,
		payload: data
	};
}


export const deleteAllImagesSuccess = () => {
	return {
		type: IMAGE_DELETE_ALL_SUCCESS,
		message: 'Image all deleted successfully.'
	};
}


export const deleteAllImagesFailed = () => {
	return {
		type: IMAGE_DELETE_ALL_FAILED
	};
}
