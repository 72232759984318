import {
  MENUS_SELECT_ALL,
  MENUS_UNSELECT_ALL,
  MENU_SELECTED,
  MENUS_LOAD,
  MENUS_LOAD_SUCCESS,
  MENUS_LOAD_FAILED,
  MENU_LOAD,
  MENU_LOAD_SUCCESS,
  MENU_LOAD_FAILED,
  MENU_ADD,
  MENU_ADD_SUCCESS,
  MENU_ADD_FAILED,
  MENU_UPDATE,
  MENU_UPDATE_SUCCESS,
  MENU_UPDATE_FAILED,
  MENU_DELETE,
  MENU_DELETE_SUCCESS,
  MENU_DELETE_FAILED,
  MENU_DELETE_ALL,
  MENU_DELETE_ALL_SUCCESS,
  MENU_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
  menus: {
    records: [],
    offset: 0,
    limit: 1000,
    totalRecords: 0
  },
  selectedMenu: null,
  selectedMenuId: null,
  selectedPdf: null,
  selectedPdfId: null,
  message: null,
  messageType: 'info',
  menuFilter: {
    offset: 0,
    limit: 1000,
    orderBy: 'name',
    filterGroups: null
  },
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MENUS_SELECT_ALL:
      return {
        ...state,
        menus: {
          ...state.menus,
          records: state.menus.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case MENUS_UNSELECT_ALL:
      return {
        ...state,
        menus: {
          ...state.menus,
          records: state.menus.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case MENU_SELECTED:
      return {
        ...state,
        selectedMenu: action.payload,
        selectedMenuId: action.payload.id
      };
    case MENUS_LOAD:
      return {
        ...state,
        menuFilter: action.payload ? action.payload : state.menuFilter
      };
    case MENUS_LOAD_SUCCESS:
      return {
        ...state,
        menus: action.payload
      };
    case MENUS_LOAD_FAILED:
      return {
        ...state
      };
    case MENU_LOAD:
      return {
        ...state
      };
    case MENU_LOAD_SUCCESS:
      return {
        ...state
      };
    case MENU_LOAD_FAILED:
      return {
        ...state
      };
    case MENU_ADD:
      return {
        ...state,
        loading: true
      };
    case MENU_ADD_SUCCESS:
      return {
        ...state,
        message: action.message,
        loading: false
      };
    case MENU_ADD_FAILED:
      return {
        ...state,
        loading: false
      };
    case MENU_UPDATE:
      return {
        ...state,
        loading: true
      };
    case MENU_UPDATE_SUCCESS:
      return {
        ...state,
        message: action.message,
        loading: false
      };
    case MENU_UPDATE_FAILED:
      return {
        ...state,
        loading: false
      };
    case MENU_DELETE:
      return {
        ...state
      };
    case MENU_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case MENU_DELETE_FAILED:
      return {
        ...state
      };
    case MENU_DELETE_ALL:
      return {
        ...state
      };
    case MENU_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case MENU_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
