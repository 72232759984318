import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadBriefArticles as loadBriefArticlesAction,
	loadBriefArticleSuccess,
	loadBriefArticlesSuccess,
	addBriefArticleSuccess,
	updateBriefArticleSuccess,
    deleteBriefArticleSuccess,
    deleteAllBriefArticlesSuccess
} from '../actions/BriefArticle';
import {
	loadBriefArticlesFailed,
	loadBriefArticleFailed,
	addBriefArticleFailed,
	updateBriefArticleFailed,
	deleteBriefArticleFailed
} from '../actions/BriefArticle';


import {
	BRIEFARTICLES_LOAD,
	BRIEFARTICLE_LOAD,
	BRIEFARTICLE_ADD,
	BRIEFARTICLE_UPDATE,
    BRIEFARTICLE_DELETE,
    BRIEFARTICLE_DELETE_ALL,
    BRIEFARTICLE_DELETE_ALL_SUCCESS,
    BRIEFARTICLE_DELETE_ALL_FAILED,
	IMAGE_LOAD,
	IMAGE_ADD,
	IMAGE_UPDATE,
	IMAGE_DELETE,
    BRIEFARTICLE_ADD_SUCCESS,
    BRIEFARTICLE_UPDATE_SUCCESS,
} from '../../constants/ActionTypes';

import {
    userSignOut
} from '../actions/Auth';

const loadBriefArticlesRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`brief-articles/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`brief-articles`, { params })
        .then(data => data)
        .catch(error => error);
}
    

const loadBriefArticleRequest = async (id) =>
    await Api().get(`brief-articles/${id}`)
        .then(data => data)
        .catch(error => error);

const addBriefArticleRequest = async (payload) =>
    await Api().post(`brief-articles`, payload)
      .then(data => data)
      .catch(error => error);

const updateBriefArticleRequest = async (payload) =>
    await Api().patch(`brief-articles/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deleteBriefArticleRequest = async (id) =>
    await Api().delete(`brief-articles/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadBriefArticles({payload}) {
    try {
        const data = yield call(loadBriefArticlesRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadBriefArticlesSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshBriefArticles({payload}) {
    yield loadBriefArticles(payload);
}

function* processLoadBriefArticles() {
    yield takeEvery(BRIEFARTICLES_LOAD, loadBriefArticles);
}

function* loadBriefArticle({selectedBriefArticleId}) {
    try {
        const data = yield call(loadBriefArticleRequest, selectedBriefArticleId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadBriefArticleSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadBriefArticle() {
    yield takeEvery(BRIEFARTICLE_LOAD, loadBriefArticle);
}


function* addBriefArticle({payload}) {
    try {
        const data = yield call(addBriefArticleRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadBriefArticlesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddBriefArticle() {
    yield takeEvery(BRIEFARTICLE_ADD, addBriefArticle);
}


function* updateBriefArticle({payload}) {
    try {
        const data = yield call(updateBriefArticleRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadBriefArticlesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateBriefArticle() {
    yield takeEvery(BRIEFARTICLE_UPDATE, updateBriefArticle);
}

function* deleteBriefArticle({payload}) {
    try {
        const data = yield call(deleteBriefArticleRequest, payload.model.id);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadBriefArticlesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllBriefArticles({payload}) {
    try {        
        for(let i = 0; i < payload.models.length; ++i){
            let data = yield call(deleteBriefArticleRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }
        
        yield put(loadBriefArticlesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteBriefArticle() {
    yield takeEvery(BRIEFARTICLE_DELETE, deleteBriefArticle);
}

function* processDeleteAllBriefArticles() {
    yield takeEvery(BRIEFARTICLE_DELETE_ALL, deleteAllBriefArticles);
}

export default function* briefArticleSagas() {
    yield all([fork(processLoadBriefArticles),
      fork(processLoadBriefArticle),
      fork(processAddBriefArticle),
      fork(processUpdateBriefArticle),
      fork(processDeleteAllBriefArticles),
      fork(processDeleteBriefArticle)]);
}