import {
	SERVICE_TYPES_SELECT_ALL,
	SERVICE_TYPES_UNSELECT_ALL,
	SERVICE_TYPE_SELECTED,
	SERVICE_TYPES_LOAD,
	SERVICE_TYPES_LOAD_SUCCESS,
  SERVICE_TYPES_LOAD_FAILED,
  SERVICE_TYPE_LOAD,
	SERVICE_TYPE_LOAD_SUCCESS,
	SERVICE_TYPE_LOAD_FAILED,
	SERVICE_TYPE_ADD,
	SERVICE_TYPE_ADD_SUCCESS,
	SERVICE_TYPE_ADD_FAILED,
	SERVICE_TYPE_UPDATE,
	SERVICE_TYPE_UPDATE_SUCCESS,
	SERVICE_TYPE_UPDATE_FAILED,
	SERVICE_TYPE_DELETE,
	SERVICE_TYPE_DELETE_SUCCESS,
	SERVICE_TYPE_DELETE_FAILED,
	SERVICE_TYPE_DELETE_ALL,
	SERVICE_TYPE_DELETE_ALL_SUCCESS,
	SERVICE_TYPE_DELETE_ALL_FAILED,
	SERVICE_TYPE_RESTORE,
	SERVICE_TYPE_RESTORE_SUCCESS,
	SERVICE_TYPE_RESTORE_FAILED
} from '../../constants/ActionTypes';

export const selectAllServiceTypes = () => {
	return {
		type: SERVICE_TYPES_SELECT_ALL
	}
}

export const unselectAllServiceTypes = () => {
	return {
		type: SERVICE_TYPES_UNSELECT_ALL
	}
}

export const selectServiceType = data => {
	return {
		type: SERVICE_TYPE_SELECTED,
		payload: data
	}
}

export const loadServiceTypes = (data) => {
	return {
		type: SERVICE_TYPES_LOAD,
		payload: data
	};
}


export const loadServiceTypesSuccess = (data) => {
	return {
        type: SERVICE_TYPES_LOAD_SUCCESS,
        payload: data
	};
}


export const loadServiceTypesFailed = () => {
	return {
		type: SERVICE_TYPES_LOAD_FAILED
	};
}


export const loadServiceType = () => {
	return {
		type: SERVICE_TYPE_LOAD
	};
}


export const loadServiceTypeSuccess = (data) => {
	return {
		type: SERVICE_TYPE_LOAD_SUCCESS,
		payload: data
	};
}


export const loadServiceTypeFailed = () => {
	return {
		type: SERVICE_TYPE_LOAD_FAILED
	};
}


export const addServiceType = (data) => {
	return {
		type: SERVICE_TYPE_ADD,
		payload: data
	};
}


export const addServiceTypeSuccess = (filter) => {
	return {
		type: SERVICE_TYPE_ADD_SUCCESS,
		message: 'ServiceType added successfully.',
		payload: filter
	};
}


export const addServiceTypeFailed = () => {
	return {
		type: SERVICE_TYPE_ADD_FAILED
	};
}


export const updateServiceType = (data) => {
	return {
		type: SERVICE_TYPE_UPDATE,
		payload: data
	};
}


export const updateServiceTypeSuccess = (data) => {
	return {
		type: SERVICE_TYPE_UPDATE_SUCCESS,
		payload: data
	};
}


export const updateServiceTypeFailed = (data) => {
	return {
		type: SERVICE_TYPE_UPDATE_FAILED,
		payload: data
	};
}


export const deleteServiceType = (data) => {
	return {
		type: SERVICE_TYPE_DELETE,
		payload: data
	};
}


export const deleteServiceTypeSuccess = () => {
	return {
		type: SERVICE_TYPE_DELETE_SUCCESS,
		message: 'ServiceType deleted successfully.'
	};
}


export const deleteServiceTypeFailed = () => {
	return {
		type: SERVICE_TYPE_DELETE_FAILED
	};
}

export const deleteAllServiceTypes = (data) => {
	return {
		type: SERVICE_TYPE_DELETE_ALL,
		payload: data
	};
}


export const deleteAllServiceTypesSuccess = () => {
	return {
		type: SERVICE_TYPE_DELETE_ALL_SUCCESS,
		message: 'ServiceType all deleted successfully.'
	};
}

export const deleteAllServiceTypesFailed = () => {
	return {
		type: SERVICE_TYPE_DELETE_ALL_FAILED
	};
}


export const restoreServiceType = (data) => {
	return {
		type: SERVICE_TYPE_RESTORE,
		payload: data
	};
}


export const restoreServiceTypeSuccess = () => {
	return {
		type: SERVICE_TYPE_RESTORE_SUCCESS,
		message: 'ServiceType restored successfully.'
	};
}


export const restoreServiceTypeFailed = () => {
	return {
		type: SERVICE_TYPE_RESTORE_FAILED
	};
}
