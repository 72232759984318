import {
	STATISTICS_SELECT_ALL,
	STATISTICS_UNSELECT_ALL,
	STATISTIC_SELECTED,
	STATISTICS_LOAD,
	STATISTICS_LOAD_SUCCESS,
    STATISTICS_LOAD_FAILED,
    STATISTIC_LOAD,
	STATISTIC_LOAD_SUCCESS,
	STATISTIC_LOAD_FAILED,
	STATISTIC_ADD,
	STATISTIC_ADD_SUCCESS,
	STATISTIC_ADD_FAILED,
	STATISTIC_UPDATE,
	STATISTIC_UPDATE_SUCCESS,
	STATISTIC_UPDATE_FAILED,
	STATISTIC_DELETE,
	STATISTIC_DELETE_SUCCESS,
	STATISTIC_DELETE_FAILED,
	STATISTIC_DELETE_ALL,
	STATISTIC_DELETE_ALL_SUCCESS,
	STATISTIC_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
    statistics: {
        records: [],
        offset: 0,
		limit: 1000,
		totalRecords: 0
    },
    selectedStatistic: null,
    selectedStatisticId: null,
  	selectedImage: null,
	selectedImageId: null,
	message: null,
	messageType: 'info',
	statisticFilter: {
		offset: 0,
		limit: 1000,
		orderBy: 'name',
		filterGroups: null
	  }
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case STATISTICS_SELECT_ALL:
			return {
				...state,
				statistics: {
					...state.statistics,
					records: state.statistics.records.map(data => {
						data.selected = true;
						return data;
					})
				}
			}
		case STATISTICS_UNSELECT_ALL:
			return {
				...state,
				statistics: {
					...state.statistics,
					records: state.statistics.records.map(data => {
						data.selected = false;
						return data;
					})
				}
			}
		case STATISTIC_SELECTED: 
			return {
				...state,
				selectedStatistic: action.payload,
				selectedStatisticId: action.payload.id
			};
		case STATISTICS_LOAD: 
			return {
				...state,
				statisticFilter: action.payload ? action.payload : state.statisticFilter
			};
		case STATISTICS_LOAD_SUCCESS:
			return {
				...state,
				statistics: action.payload
			};
		case STATISTICS_LOAD_FAILED:
			return {
				...state
            };
        case STATISTIC_LOAD: 
			return {
				...state
			};
		case STATISTIC_LOAD_SUCCESS:
			return {
				...state
			};
		case STATISTIC_LOAD_FAILED:
			return {
				...state
			};
		case STATISTIC_ADD:
			return {
				...state
			};
		case STATISTIC_ADD_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case STATISTIC_ADD_FAILED:
			return {
				...state
			};
		case STATISTIC_UPDATE:
			return {
				...state
			};
		case STATISTIC_UPDATE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case STATISTIC_UPDATE_FAILED:
			return {
				...state
			};
		case STATISTIC_DELETE:
			return {
				...state
			};
		case STATISTIC_DELETE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case STATISTIC_DELETE_FAILED:
			return {
				...state
			};
		case STATISTIC_DELETE_ALL:
			return {
				...state
			};
		case STATISTIC_DELETE_ALL_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case STATISTIC_DELETE_ALL_FAILED:
			return {
				...state
			};
		default:
			return state;
	}
}
