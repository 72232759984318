import {
	PRODUCTS_SELECT_ALL,
	PRODUCTS_UNSELECT_ALL,
	PRODUCT_SELECTED,
	PRODUCTS_LOAD,
	PRODUCTS_LOAD_SUCCESS,
    PRODUCTS_LOAD_FAILED,
    PRODUCT_LOAD,
	PRODUCT_LOAD_SUCCESS,
	PRODUCT_LOAD_FAILED,
	PRODUCT_ADD,
	PRODUCT_ADD_SUCCESS,
	PRODUCT_ADD_FAILED,
	PRODUCT_UPDATE,
	PRODUCT_UPDATE_SUCCESS,
	PRODUCT_UPDATE_FAILED,
	PRODUCT_DELETE,
	PRODUCT_DELETE_SUCCESS,
	PRODUCT_DELETE_FAILED,
	PRODUCT_DELETE_ALL,
	PRODUCT_DELETE_ALL_SUCCESS,
	PRODUCT_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
    products: {
        records: [],
        offset: 0,
		limit: 1000,
		totalRecords: 0
    },
    selectedProduct: null,
    selectedProductId: null,
	selectedPdf: null,
	selectedPdfId: null,
	message: null,
	messageType: 'info',
	productFilter: {
		offset: 0,
		limit: 1000,
		orderBy: 'name',
		filterGroups: null
	  }
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case PRODUCTS_SELECT_ALL:
			return {
				...state,
				products: {
					...state.products,
					records: state.products.records.map(data => {
						data.selected = true;
						return data;
					})
				}
			}
		case PRODUCTS_UNSELECT_ALL:
			return {
				...state,
				products: {
					...state.products,
					records: state.products.records.map(data => {
						data.selected = false;
						return data;
					})
				}
			}
		case PRODUCT_SELECTED: 
			return {
				...state,
				selectedProduct: action.payload,
				selectedProductId: action.payload.id
			};
		case PRODUCTS_LOAD: 
			return {
				...state,
				productFilter: action.payload ? action.payload : state.productFilter
			};
		case PRODUCTS_LOAD_SUCCESS:
			return {
				...state,
				products: action.payload
			};
		case PRODUCTS_LOAD_FAILED:
			return {
				...state
            };
        case PRODUCT_LOAD: 
			return {
				...state
			};
		case PRODUCT_LOAD_SUCCESS:
			return {
				...state
			};
		case PRODUCT_LOAD_FAILED:
			return {
				...state
			};
		case PRODUCT_ADD:
			return {
				...state
			};
		case PRODUCT_ADD_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PRODUCT_ADD_FAILED:
			return {
				...state
			};
		case PRODUCT_UPDATE:
			return {
				...state
			};
		case PRODUCT_UPDATE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PRODUCT_UPDATE_FAILED:
			return {
				...state
			};
		case PRODUCT_DELETE:
			return {
				...state
			};
		case PRODUCT_DELETE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PRODUCT_DELETE_FAILED:
			return {
				...state
			};
		case PRODUCT_DELETE_ALL:
			return {
				...state
			};
		case PRODUCT_DELETE_ALL_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PRODUCT_DELETE_ALL_FAILED:
			return {
				...state
			};
		default:
			return state;
	}
}