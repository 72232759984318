import {
	ABOUTUSES_SELECT_ALL,
	ABOUTUSES_UNSELECT_ALL,
	ABOUTUS_SELECTED,
	ABOUTUSES_LOAD,
	ABOUTUSES_LOAD_SUCCESS,
    ABOUTUSES_LOAD_FAILED,
    ABOUTUS_LOAD,
	ABOUTUS_LOAD_SUCCESS,
	ABOUTUS_LOAD_FAILED,
	ABOUTUS_ADD,
	ABOUTUS_ADD_SUCCESS,
	ABOUTUS_ADD_FAILED,
	ABOUTUS_UPDATE,
	ABOUTUS_UPDATE_SUCCESS,
	ABOUTUS_UPDATE_FAILED,
	ABOUTUS_DELETE,
	ABOUTUS_DELETE_SUCCESS,
	ABOUTUS_DELETE_FAILED,
	ABOUTUS_DELETE_ALL,
	ABOUTUS_DELETE_ALL_SUCCESS,
	ABOUTUS_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

export const selectAllAboutUses = () => {
	return {
		type: ABOUTUSES_SELECT_ALL
	}
}

export const unselectAllAboutUses = () => {
	return {
		type: ABOUTUSES_UNSELECT_ALL
	}
}

export const selectAboutUs = data => {
	return {
		type: ABOUTUS_SELECTED,
		payload: data
	}
}

export const loadAboutUses = (data) => {
	return {
		type: ABOUTUSES_LOAD,
		payload: data
	};
}


export const loadAboutUsesSuccess = (data) => {
	return {
        type: ABOUTUSES_LOAD_SUCCESS,
        payload: data
	};
}


export const loadAboutUsesFailed = () => {
	return {
		type: ABOUTUSES_LOAD_FAILED
	};
}


export const loadAboutUs = () => {
	return {
		type: ABOUTUS_LOAD
	};
}


export const loadAboutUsSuccess = (data) => {
	return {
		type: ABOUTUS_LOAD_SUCCESS,
		payload: data
	};
}


export const loadAboutUsFailed = () => {
	return {
		type: ABOUTUS_LOAD_FAILED
	};
}


export const addAboutUs = (data) => {
	return {
		type: ABOUTUS_ADD,
		payload: data
	};
}


export const addAboutUsSuccess = (filter) => {
	return {
		type: ABOUTUS_ADD_SUCCESS,
		message: 'AboutUs added successfully.',
		payload: filter
	};
}


export const addAboutUsFailed = () => {
	return {
		type: ABOUTUS_ADD_FAILED
	};
}


export const updateAboutUs = (data) => {
	return {
		type: ABOUTUS_UPDATE,
		payload: data
	};
}


export const updateAboutUsSuccess = (data) => {
	return {
		type: ABOUTUS_UPDATE_SUCCESS,
		payload: data
	};
}


export const updateAboutUsFailed = (data) => {
	return {
		type: ABOUTUS_UPDATE_FAILED,
		payload: data
	};
}


export const deleteAboutUs = (data) => {
	return {
		type: ABOUTUS_DELETE,
		payload: data
	};
}


export const deleteAboutUsSuccess = () => {
	return {
		type: ABOUTUS_DELETE_SUCCESS,
		message: 'AboutUs deleted successfully.'
	};
}


export const deleteAboutUsFailed = () => {
	return {
		type: ABOUTUS_DELETE_FAILED
	};
}

export const deleteAllAboutUses = (data) => {
	return {
		type: ABOUTUS_DELETE_ALL,
		payload: data
	};
}


export const deleteAllAboutUsesSuccess = () => {
	return {
		type: ABOUTUS_DELETE_ALL_SUCCESS,
		message: 'AboutUs all deleted successfully.'
	};
}


export const deleteAllAboutUsesFailed = () => {
	return {
		type: ABOUTUS_DELETE_ALL_FAILED
	};
}