import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadIntroductions as loadIntroductionsAction,
	loadIntroductionSuccess,
	loadIntroductionsSuccess,
	addIntroductionSuccess,
	updateIntroductionSuccess,
    deleteIntroductionSuccess,
    deleteAllIntroductionsSuccess
} from '../actions/Introduction';
import {
	loadIntroductionsFailed,
	loadIntroductionFailed,
	addIntroductionFailed,
	updateIntroductionFailed,
	deleteIntroductionFailed
} from '../actions/Introduction';


import {
	INTRODUCTIONS_LOAD,
	INTRODUCTION_LOAD,
	INTRODUCTION_ADD,
	INTRODUCTION_UPDATE,
    INTRODUCTION_DELETE,
    INTRODUCTION_DELETE_ALL,
    INTRODUCTION_DELETE_ALL_SUCCESS,
    INTRODUCTION_DELETE_ALL_FAILED,
	IMAGE_LOAD,
	IMAGE_ADD,
	IMAGE_UPDATE,
	IMAGE_DELETE,
    INTRODUCTION_ADD_SUCCESS,
    INTRODUCTION_UPDATE_SUCCESS,
} from '../../constants/ActionTypes';

import {
    userSignOut
} from '../actions/Auth';

const loadIntroductionsRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`introductions/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`introductions`, { params })
        .then(data => data)
        .catch(error => error);
}
    

const loadIntroductionRequest = async (id) =>
    await Api().get(`introductions/${id}`)
        .then(data => data)
        .catch(error => error);

const addIntroductionRequest = async (payload) =>
    await Api().post(`introductions`, payload)
      .then(data => data)
      .catch(error => error);

const updateIntroductionRequest = async (payload) =>
    await Api().patch(`introductions/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deleteIntroductionRequest = async (id) =>
    await Api().delete(`introductions/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadIntroductions({payload}) {
    try {
        const data = yield call(loadIntroductionsRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadIntroductionsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshIntroductions({payload}) {
    yield loadIntroductions(payload);
}

function* processLoadIntroductions() {
    yield takeEvery(INTRODUCTIONS_LOAD, loadIntroductions);
}

function* loadIntroduction({selectedIntroductionId}) {
    try {
        const data = yield call(loadIntroductionRequest, selectedIntroductionId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadIntroductionSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadIntroduction() {
    yield takeEvery(INTRODUCTION_LOAD, loadIntroduction);
}


function* addIntroduction({payload}) {
    try {
        const data = yield call(addIntroductionRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadIntroductionsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddIntroduction() {
    yield takeEvery(INTRODUCTION_ADD, addIntroduction);
}


function* updateIntroduction({payload}) {
    try {
        const data = yield call(updateIntroductionRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadIntroductionsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateIntroduction() {
    yield takeEvery(INTRODUCTION_UPDATE, updateIntroduction);
}

function* deleteIntroduction({payload}) {
    try {
        const data = yield call(deleteIntroductionRequest, payload.model.id);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadIntroductionsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllIntroductions({payload}) {
    try {        
        for(let i = 0; i < payload.models.length; ++i){
            let data = yield call(deleteIntroductionRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }
        
        yield put(loadIntroductionsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteIntroduction() {
    yield takeEvery(INTRODUCTION_DELETE, deleteIntroduction);
}

function* processDeleteAllIntroductions() {
    yield takeEvery(INTRODUCTION_DELETE_ALL, deleteAllIntroductions);
}

export default function* introductionSagas() {
    yield all([fork(processLoadIntroductions),
      fork(processLoadIntroduction),
      fork(processAddIntroduction),
      fork(processUpdateIntroduction),
      fork(processDeleteAllIntroductions),
      fork(processDeleteIntroduction)]);
}
