import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadProducts as loadProductsAction,
	loadProductSuccess,
	loadProductsSuccess,
	addProductSuccess,
	updateProductSuccess,
    deleteProductSuccess,
    deleteAllProductsSuccess
} from '../actions/Product';
import {
	loadProductsFailed,
	loadProductFailed,
	addProductFailed,
	updateProductFailed,
	deleteProductFailed
} from '../actions/Product';


import {
	PRODUCTS_LOAD,
	PRODUCT_LOAD,
	PRODUCT_ADD,
	PRODUCT_UPDATE,
    PRODUCT_DELETE,
    PRODUCT_DELETE_ALL,
    PRODUCT_DELETE_ALL_SUCCESS,
    PRODUCT_DELETE_ALL_FAILED,
	PDF_LOAD,
	PDF_ADD,
	PDF_UPDATE,
	PDF_DELETE,
    PRODUCT_ADD_SUCCESS,
    PRODUCT_UPDATE_SUCCESS,
} from '../../constants/ActionTypes';

import {
    userSignOut
} from '../actions/Auth';

const loadProductsRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`products/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`products`, { params })
        .then(data => data)
        .catch(error => error);
}
    

const loadProductRequest = async (id) =>
    await Api().get(`products/${id}`)
        .then(data => data)
        .catch(error => error);

const addProductRequest = async (payload) =>
    await Api().post(`products`, payload)
      .then(data => data)
      .catch(error => error);

const updateProductRequest = async (payload) =>
    await Api().patch(`products/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deleteProductRequest = async (id) =>
    await Api().delete(`products/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadProducts({payload}) {
    try {
        const data = yield call(loadProductsRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProductsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshProducts({payload}) {
    yield loadProducts(payload);
}

function* processLoadProducts() {
    yield takeEvery(PRODUCTS_LOAD, loadProducts);
}

function* loadProduct({selectedProductId}) {
    try {
        const data = yield call(loadProductRequest, selectedProductId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProductSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadProduct() {
    yield takeEvery(PRODUCT_LOAD, loadProduct);
}


function* addProduct({payload}) {
    try {
        const data = yield call(addProductRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProductsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddProduct() {
    yield takeEvery(PRODUCT_ADD, addProduct);
}


function* updateProduct({payload}) {
    try {
        const data = yield call(updateProductRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProductsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateProduct() {
    yield takeEvery(PRODUCT_UPDATE, updateProduct);
}

function* deleteProduct({payload}) {
    try {
        const data = yield call(deleteProductRequest, payload.model.id);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProductsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllProducts({payload}) {
    try {        
        for(let i = 0; i < payload.models.length; ++i){
            let data = yield call(deleteProductRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }
        
        yield put(loadProductsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteProduct() {
    yield takeEvery(PRODUCT_DELETE, deleteProduct);
}

function* processDeleteAllProducts() {
    yield takeEvery(PRODUCT_DELETE_ALL, deleteAllProducts);
}

export default function* productSagas() {
    yield all([fork(processLoadProducts),
      fork(processLoadProduct),
      fork(processAddProduct),
      fork(processUpdateProduct),
      fork(processDeleteAllProducts),
      fork(processDeleteProduct)]);
}