import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadServiceTypes as loadServiceTypesAction,
	loadServiceTypeSuccess,
	loadServiceTypesSuccess,
	addServiceTypeSuccess,
	updateServiceTypeSuccess,
    deleteServiceTypeSuccess,
    deleteAllServiceTypesSuccess
} from '../actions/ServiceType';

import {
	SERVICE_TYPES_LOAD,
	SERVICE_TYPE_LOAD,
	SERVICE_TYPE_ADD,
	SERVICE_TYPE_UPDATE,
    SERVICE_TYPE_DELETE,
    SERVICE_TYPE_DELETE_ALL,
    SERVICE_TYPE_DELETE_ALL_SUCCESS,
    SERVICE_TYPE_DELETE_ALL_FAILED,
	PDF_LOAD,
	PDF_ADD,
	PDF_UPDATE,
	PDF_DELETE,
    SERVICE_TYPE_ADD_SUCCESS,
    SERVICE_TYPE_UPDATE_SUCCESS,
    SERVICE_TYPE_RESTORE,
} from '../../constants/ActionTypes';

import {
    userSignOut
} from '../actions/Auth';

const loadServiceTypesRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`service-types/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`service-types`, { params })
        .then(data => data)
        .catch(error => error);
}


const loadServiceTypeRequest = async (id) =>
    await Api().get(`service-types/${id}`)
        .then(data => data)
        .catch(error => error);

const addServiceTypeRequest = async (payload) =>
    await Api().post(`service-types`, payload)
      .then(data => data)
      .catch(error => error);

const updateServiceTypeRequest = async (payload) =>
    await Api().patch(`service-types/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deleteServiceTypeRequest = async (id) =>
    await Api().delete(`service-types/${id}`)
    .then(data => data)
    .catch(error => error);

const removeServiceTypeRequest = async (id) =>
    await Api().delete(`service-types/remove/${id}`)
    .then(data => data)
    .catch(error => error);

const restoreServiceTypeRequest = async (id) =>
    await Api().delete(`service-types/restore/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadServiceTypes({payload}) {
    try {
        const data = yield call(loadServiceTypesRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadServiceTypesSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshServiceTypes({payload}) {
    yield loadServiceTypes(payload);
}

function* processLoadServiceTypes() {
    yield takeEvery(SERVICE_TYPES_LOAD, loadServiceTypes);
}

function* loadServiceType({selectedServiceTypeId}) {
    try {
        const data = yield call(loadServiceTypeRequest, selectedServiceTypeId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadServiceTypeSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadServiceType() {
    yield takeEvery(SERVICE_TYPE_LOAD, loadServiceType);
}


function* addServiceType({payload}) {
    try {
        const data = yield call(addServiceTypeRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadServiceTypesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddServiceType() {
    yield takeEvery(SERVICE_TYPE_ADD, addServiceType);
}


function* updateServiceType({payload}) {
    try {
        const data = yield call(updateServiceTypeRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadServiceTypesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateServiceType() {
    yield takeEvery(SERVICE_TYPE_UPDATE, updateServiceType);
}

function* deleteServiceType({payload}) {
    try {
        let data = null;
        if (payload.isRemove) {
            data = yield call(removeServiceTypeRequest, payload.model.id);
        } else {
            data = yield call(deleteServiceTypeRequest, payload.model.id);
        }     
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadServiceTypesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllServiceTypes({payload}) {
    try {
        for(let i = 0; i < payload.models.length; ++i){
            let data = yield call(deleteServiceTypeRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }

        yield put(loadServiceTypesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* restoreServiceType({payload}) {
    try {
        let data = yield call(restoreServiceTypeRequest, payload.model.id);             
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadServiceTypesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteServiceType() {
    yield takeEvery(SERVICE_TYPE_DELETE, deleteServiceType);
}

function* processDeleteAllServiceTypes() {
    yield takeEvery(SERVICE_TYPE_DELETE_ALL, deleteAllServiceTypes);
}

function* processRestoreServiceType() {
    yield takeEvery(SERVICE_TYPE_RESTORE, restoreServiceType);
}

export default function* serviceTypeSagas() {
    yield all([fork(processLoadServiceTypes),
      fork(processLoadServiceType),
      fork(processAddServiceType),
      fork(processUpdateServiceType),
      fork(processDeleteAllServiceTypes),
      fork(processDeleteServiceType),
      fork(processRestoreServiceType)
    ]);
}
