import {
  SERVICE_TYPES_SELECT_ALL,
  SERVICE_TYPES_UNSELECT_ALL,
  SERVICE_TYPE_SELECTED,
  SERVICE_TYPES_LOAD,
  SERVICE_TYPES_LOAD_SUCCESS,
  SERVICE_TYPES_LOAD_FAILED,
  SERVICE_TYPE_LOAD,
  SERVICE_TYPE_LOAD_SUCCESS,
  SERVICE_TYPE_LOAD_FAILED,
  SERVICE_TYPE_ADD,
  SERVICE_TYPE_ADD_SUCCESS,
  SERVICE_TYPE_ADD_FAILED,
  SERVICE_TYPE_UPDATE,
  SERVICE_TYPE_UPDATE_SUCCESS,
  SERVICE_TYPE_UPDATE_FAILED,
  SERVICE_TYPE_DELETE,
  SERVICE_TYPE_DELETE_SUCCESS,
  SERVICE_TYPE_DELETE_FAILED,
  SERVICE_TYPE_DELETE_ALL,
  SERVICE_TYPE_DELETE_ALL_SUCCESS,
  SERVICE_TYPE_DELETE_ALL_FAILED,
  SERVICE_TYPE_RESTORE,
  SERVICE_TYPE_RESTORE_SUCCESS,
  SERVICE_TYPE_RESTORE_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
  serviceTypes: {
    records: [],
    offset: 0,
    limit: 1000,
    totalRecords: 0
  },
  selectedServiceType: null,
  selectedServiceTypeId: null,
  selectedPdf: null,
  selectedPdfId: null,
  message: null,
  messageType: 'info',
  serviceTypeFilter: {
    offset: 0,
    limit: 1000,
    orderBy: 'name',
    filterGroups: [{
      filters: [{
        property: 'isDeleted',
        operator: '=',
        value: false
      }]
    }]
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SERVICE_TYPES_SELECT_ALL:
      return {
        ...state,
        serviceTypes: {
          ...state.serviceTypes,
          records: state.serviceTypes.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case SERVICE_TYPES_UNSELECT_ALL:
      return {
        ...state,
        serviceTypes: {
          ...state.serviceTypes,
          records: state.serviceTypes.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case SERVICE_TYPE_SELECTED:
      return {
        ...state,
        selectedServiceType: action.payload,
        selectedServiceTypeId: action.payload.id
      };
    case SERVICE_TYPES_LOAD:
      return {
        ...state,
        serviceTypeFilter: action.payload ? action.payload : state.serviceTypeFilter
      };
    case SERVICE_TYPES_LOAD_SUCCESS:
      return {
        ...state,
        serviceTypes: action.payload
      };
    case SERVICE_TYPES_LOAD_FAILED:
      return {
        ...state
      };
    case SERVICE_TYPE_LOAD:
      return {
        ...state
      };
    case SERVICE_TYPE_LOAD_SUCCESS:
      return {
        ...state
      };
    case SERVICE_TYPE_LOAD_FAILED:
      return {
        ...state
      };
    case SERVICE_TYPE_ADD:
      return {
        ...state
      };
    case SERVICE_TYPE_ADD_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SERVICE_TYPE_ADD_FAILED:
      return {
        ...state
      };
    case SERVICE_TYPE_UPDATE:
      return {
        ...state
      };
    case SERVICE_TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SERVICE_TYPE_UPDATE_FAILED:
      return {
        ...state
      };
    case SERVICE_TYPE_DELETE:
      return {
        ...state
      };
    case SERVICE_TYPE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SERVICE_TYPE_DELETE_FAILED:
      return {
        ...state
      };
    case SERVICE_TYPE_DELETE_ALL:
      return {
        ...state
      };
    case SERVICE_TYPE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SERVICE_TYPE_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case SERVICE_TYPE_RESTORE:
      return {
        ...state
      };
    case SERVICE_TYPE_RESTORE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SERVICE_TYPE_RESTORE_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
