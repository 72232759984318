import React from "react";
import {Route, Switch} from "react-router-dom";
import { connect } from "react-redux";
import Widgets from "./Widgets";
import Metrics from "./Metrics";
import Dashboard from "./dashboard";
import Layouts from "./Layouts";
import asyncComponent from "../../util/asyncComponent";
import CircularProgress from "../../components/CircularProgress";

const Main = ({match}, props) => (
  <div>
    <Switch>
      <Route path={`${match.url}/dashboard`} component={Dashboard}/>
      <Route path={`${match.url}/widgets`} component={Widgets}/>
      <Route path={`${match.url}/metrics`} component={Metrics}/>
      <Route path={`${match.url}/layouts`} component={Layouts}/>
      <Route path={`${match.url}/albums`} component={asyncComponent(() => import('./Album'))}/>
      <Route path={`${match.url}/pdf-albums`} component={asyncComponent(() => import('./PdfAlbum'))}/>
      <Route path={`${match.url}/projects`} component={asyncComponent(() => import('./Project'))}/>
      <Route path={`${match.url}/product-types`} component={asyncComponent(() => import('./ProductType'))}/>
      <Route path={`${match.url}/products`} component={asyncComponent(() => import('./Product'))}/>
      <Route path={`${match.url}/service-types`} component={asyncComponent(() => import('./ServiceType'))}/>
      <Route path={`${match.url}/brief-articles`} component={asyncComponent(() => import('./BriefArticle'))}/>    
      <Route path={`${match.url}/contacts`} component={asyncComponent(() => import('./Contact'))}/>
      <Route path={`${match.url}/about-us`} component={asyncComponent(() => import('./AboutUs'))}/>
      <Route path={`${match.url}/images`} component={asyncComponent(() => import('./Image'))}/>
      <Route path={`${match.url}/pdfs`} component={asyncComponent(() => import('./Pdf'))}/>
      <Route path={`${match.url}/statistics`} component={asyncComponent(() => import('./Statistic'))}/>
      <Route path={`${match.url}/recycle-bin`} component={asyncComponent(() => import('./RecycleBin'))}/>
      {/* <Route path={`${match.url}/menus`} component={asyncComponent(() => import('./Menu'))}/> */}
    </Switch>
    {props.loader &&
      <div className="gx-loader-view">
        <CircularProgress className="gx-loader-main" />
      </div>
    }
  </div>  
);

const mapStateToProps = ({auth}) => {
  return {...auth}
};

export default connect(mapStateToProps)(Main);
