import {
	IMAGES_SELECT_ALL,
	IMAGES_UNSELECT_ALL,
	IMAGE_SELECTED,
	IMAGES_LOAD,
	IMAGES_LOAD_SUCCESS,
    IMAGES_LOAD_FAILED,
    IMAGE_LOAD,
	IMAGE_LOAD_SUCCESS,
	IMAGE_LOAD_FAILED,
	IMAGE_ADD,
	IMAGE_ADD_SUCCESS,
	IMAGE_ADD_FAILED,
	IMAGE_UPDATE,
	IMAGE_UPDATE_SUCCESS,
	IMAGE_UPDATE_FAILED,
	IMAGE_DELETE,
	IMAGE_DELETE_SUCCESS,
	IMAGE_DELETE_FAILED,
	IMAGE_DELETE_ALL,
	IMAGE_DELETE_ALL_SUCCESS,
	IMAGE_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
    images: {
        records: [],
        offset: 0,
		limit: 1000,
		totalRecords: 0
    },
    selectedImage: null,
    selectedImageId: null,
	message: null,
	messageType: 'info',
	imageFilter: {
		offset: 0,
		limit: 1000,
		orderBy: 'name',
		filterGroups: null
	  }
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case IMAGES_SELECT_ALL:
			return {
				...state,
				images: {
					...state.images,
					records: state.images.records.map(data => {
						data.selected = true;
						return data;
					})
				}
			}
		case IMAGES_UNSELECT_ALL:
			return {
				...state,
				images: {
					...state.images,
					records: state.images.records.map(data => {
						data.selected = false;
						return data;
					})
				}
			}
		case IMAGE_SELECTED: 
			return {
				...state,
				selectedImage: action.payload,
				selectedImageId: action.payload.id
			};
		case IMAGES_LOAD: 
			return {
				...state,
				contactFilter: action.payload ? action.payload : state.contactFilter
			};
		case IMAGES_LOAD_SUCCESS:
			return {
				...state,
				images: action.payload
			};
		case IMAGES_LOAD_FAILED:
			return {
				...state
            };
        case IMAGE_LOAD: 
			return {
				...state
			};
		case IMAGE_LOAD_SUCCESS:
			return {
				...state
			};
		case IMAGE_LOAD_FAILED:
			return {
				...state
			};
		case IMAGE_ADD:
			return {
				...state
			};
		case IMAGE_ADD_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case IMAGE_ADD_FAILED:
			return {
				...state
			};
		case IMAGE_UPDATE:
			return {
				...state
			};
		case IMAGE_UPDATE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case IMAGE_UPDATE_FAILED:
			return {
				...state
			};
		case IMAGE_DELETE:
			return {
				...state
			};
		case IMAGE_DELETE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case IMAGE_DELETE_FAILED:
			return {
				...state
			};
		case IMAGE_DELETE_ALL:
			return {
				...state
			};
		case IMAGE_DELETE_ALL_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case IMAGE_DELETE_ALL_FAILED:
			return {
				...state
			};
		default:
			return state;
	}
}