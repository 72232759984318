import {
	PDFALBUMS_SELECT_ALL,
	PDFALBUMS_UNSELECT_ALL,
	PDFALBUM_SELECTED,
	PDFALBUMS_LOAD,
	PDFALBUMS_LOAD_SUCCESS,
    PDFALBUMS_LOAD_FAILED,
    PDFALBUM_LOAD,
	PDFALBUM_LOAD_SUCCESS,
	PDFALBUM_LOAD_FAILED,
	PDFALBUM_ADD,
	PDFALBUM_ADD_SUCCESS,
	PDFALBUM_ADD_FAILED,
	PDFALBUM_UPDATE,
	PDFALBUM_UPDATE_SUCCESS,
	PDFALBUM_UPDATE_FAILED,
	PDFALBUM_DELETE,
	PDFALBUM_DELETE_SUCCESS,
	PDFALBUM_DELETE_FAILED,
	PDFALBUM_DELETE_ALL,
	PDFALBUM_DELETE_ALL_SUCCESS,
	PDFALBUM_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
    pdfAlbums: {
        records: [],
        offset: 0,
		limit: 50,
		totalRecords: 0
    },
    selectedPdfAlbum: null,
    selectedPdfAlbumId: null,
	message: null,
	messageType: 'info',
	pdfAlbumFilter: {
		offset: 0,
		limit: 1000,
		orderBy: 'name',
		filterGroups: null
	  }
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case PDFALBUMS_SELECT_ALL:
			return {
				...state,
				pdfAlbums: {
					...state.pdfAlbums,
					records: state.pdfAlbums.records.map(data => {
						data.selected = true;
						return data;
					})
				}
			}
		case PDFALBUMS_UNSELECT_ALL:
			return {
				...state,
				pdfAlbums: {
					...state.pdfAlbums,
					records: state.pdfAlbums.records.map(data => {
						data.selected = false;
						return data;
					})
				}
			}
		case PDFALBUM_SELECTED: 
			return {
				...state,
				selectedPdfAlbum: action.payload,
				selectedPdfAlbumId: action.payload.id
			};
		case PDFALBUMS_LOAD: 
			return {
				...state,
				pdfAlbumFilter: action.payload ? action.payload : state.pdfAlbumFilter
			};
		case PDFALBUMS_LOAD_SUCCESS:
			return {
				...state,
				pdfAlbums: action.payload
			};
		case PDFALBUMS_LOAD_FAILED:
			return {
				...state
            };
        case PDFALBUM_LOAD: 
			return {
				...state
			};
		case PDFALBUM_LOAD_SUCCESS:
			return {
				...state
			};
		case PDFALBUM_LOAD_FAILED:
			return {
				...state
			};
		case PDFALBUM_ADD:
			return {
				...state
			};
		case PDFALBUM_ADD_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PDFALBUM_ADD_FAILED:
			return {
				...state
			};
		case PDFALBUM_UPDATE:
			return {
				...state
			};
		case PDFALBUM_UPDATE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PDFALBUM_UPDATE_FAILED:
			return {
				...state
			};
		case PDFALBUM_DELETE:
			return {
				...state
			};
		case PDFALBUM_DELETE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PDFALBUM_DELETE_FAILED:
			return {
				...state
			};
		case PDFALBUM_DELETE_ALL:
			return {
				...state
			};
		case PDFALBUM_DELETE_ALL_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PDFALBUM_DELETE_ALL_FAILED:
			return {
				...state
			};
		default:
			return state;
	}
}