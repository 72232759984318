import {
	CONTACTS_SELECT_ALL,
	CONTACTS_UNSELECT_ALL,
	CONTACT_SELECTED,
	CONTACTS_LOAD,
	CONTACTS_LOAD_SUCCESS,
    CONTACTS_LOAD_FAILED,
    CONTACT_LOAD,
	CONTACT_LOAD_SUCCESS,
	CONTACT_LOAD_FAILED,
	CONTACT_ADD,
	CONTACT_ADD_SUCCESS,
	CONTACT_ADD_FAILED,
	CONTACT_UPDATE,
	CONTACT_UPDATE_SUCCESS,
	CONTACT_UPDATE_FAILED,
	CONTACT_DELETE,
	CONTACT_DELETE_SUCCESS,
	CONTACT_DELETE_FAILED,
	CONTACT_DELETE_ALL,
	CONTACT_DELETE_ALL_SUCCESS,
	CONTACT_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
    contacts: {
        records: [],
        offset: 0,
		limit: 1000,
		totalRecords: 0
    },
    selectedContact: null,
    selectedContactId: null,
	selectedImage: null,
	selectedImageId: null,
	message: null,
	messageType: 'info',
	contactFilter: {
		offset: 0,
		limit: 1000,
		orderBy: 'name',
		filterGroups: null
	  }
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case CONTACTS_SELECT_ALL:
			return {
				...state,
				contacts: {
					...state.contacts,
					records: state.contacts.records.map(data => {
						data.selected = true;
						return data;
					})
				}
			}
		case CONTACTS_UNSELECT_ALL:
			return {
				...state,
				contacts: {
					...state.contacts,
					records: state.contacts.records.map(data => {
						data.selected = false;
						return data;
					})
				}
			}
		case CONTACT_SELECTED: 
			return {
				...state,
				selectedContact: action.payload,
				selectedContactId: action.payload.id
			};
		case CONTACTS_LOAD: 
			return {
				...state,
				contactFilter: action.payload ? action.payload : state.contactFilter
			};
		case CONTACTS_LOAD_SUCCESS:
			return {
				...state,
				contacts: action.payload
			};
		case CONTACTS_LOAD_FAILED:
			return {
				...state
            };
        case CONTACT_LOAD: 
			return {
				...state
			};
		case CONTACT_LOAD_SUCCESS:
			return {
				...state
			};
		case CONTACT_LOAD_FAILED:
			return {
				...state
			};
		case CONTACT_ADD:
			return {
				...state
			};
		case CONTACT_ADD_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case CONTACT_ADD_FAILED:
			return {
				...state
			};
		case CONTACT_UPDATE:
			return {
				...state
			};
		case CONTACT_UPDATE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case CONTACT_UPDATE_FAILED:
			return {
				...state
			};
		case CONTACT_DELETE:
			return {
				...state
			};
		case CONTACT_DELETE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case CONTACT_DELETE_FAILED:
			return {
				...state
			};
		case CONTACT_DELETE_ALL:
			return {
				...state
			};
		case CONTACT_DELETE_ALL_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case CONTACT_DELETE_ALL_FAILED:
			return {
				...state
			};
		default:
			return state;
	}
}