import React, {Component} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {connect} from "react-redux";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
      <Auxiliary>
        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">

              <MenuItemGroup key="main" className="gx-menu-group" title={<IntlMessages id="sidebar.main"/>}>

                {/* <Menu.Item key="main/menus">
                  <Link to="/main/menus"><i className="icon icon-contacts"/>Menus</Link>
                </Menu.Item> */}

                <Menu.Item key="main/albums">
                  <Link to="/main/albums"><i className="icon icon-contacts"/>Albums</Link>
                </Menu.Item>

                <Menu.Item key="main/pdf-albums">
                  <Link to="/main/pdf-albums"><i className="icon icon-contacts"/>Pdfs</Link>
                </Menu.Item>

                <Menu.Item key="main/product-types">
                  <Link to="/main/product-types">
                    <i className="icon icon-contacts"/>Product Types
                  </Link>
                </Menu.Item>

                <Menu.Item key="main/products">
                  <Link to="/main/products">
                    <i className="icon icon-contacts"/>Products
                  </Link>
                </Menu.Item>

                <Menu.Item key="main/service-types">
                  <Link to="/main/service-types"><i className="icon icon-contacts"/>Service Types</Link>
                </Menu.Item>

                <Menu.Item key="main/brief-articles">
                  <Link to="/main/brief-articles"><i className="icon icon-contacts"/>Services</Link>
                </Menu.Item>

                <Menu.Item key="main/contacts">
                  <Link to="/main/contacts"><i className="icon icon-contacts"/>Contacts</Link>
                </Menu.Item>

                <Menu.Item key="main/about-us">
                  <Link to="/main/about-us"><i className="icon icon-contacts"/>About Us</Link>
                </Menu.Item>

                <Menu.Item key="main/statistics">
                  <Link to="/main/statistics"><i className="icon icon-contacts"/>Statistics</Link>
                </Menu.Item>

                <Menu.Item key="main/recycle-bin">
                  <Link to="/main/recycle-bin"><i className="icon icon-contacts"/>Recycle Bin</Link>
                </Menu.Item>

              </MenuItemGroup>


            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps)(SidebarContent);

