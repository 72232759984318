import {
	BRIEFARTICLES_SELECT_ALL,
	BRIEFARTICLES_UNSELECT_ALL,
	BRIEFARTICLE_SELECTED,
	BRIEFARTICLES_LOAD,
	BRIEFARTICLES_LOAD_SUCCESS,
    BRIEFARTICLES_LOAD_FAILED,
    BRIEFARTICLE_LOAD,
	BRIEFARTICLE_LOAD_SUCCESS,
	BRIEFARTICLE_LOAD_FAILED,
	BRIEFARTICLE_ADD,
	BRIEFARTICLE_ADD_SUCCESS,
	BRIEFARTICLE_ADD_FAILED,
	BRIEFARTICLE_UPDATE,
	BRIEFARTICLE_UPDATE_SUCCESS,
	BRIEFARTICLE_UPDATE_FAILED,
	BRIEFARTICLE_DELETE,
	BRIEFARTICLE_DELETE_SUCCESS,
	BRIEFARTICLE_DELETE_FAILED,
	BRIEFARTICLE_DELETE_ALL,
	BRIEFARTICLE_DELETE_ALL_SUCCESS,
	BRIEFARTICLE_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

export const selectAllBriefArticles = () => {
	return {
		type: BRIEFARTICLES_SELECT_ALL
	}
}

export const unselectAllBriefArticles = () => {
	return {
		type: BRIEFARTICLES_UNSELECT_ALL
	}
}

export const selectBriefArticle = data => {
	return {
		type: BRIEFARTICLE_SELECTED,
		payload: data
	}
}

export const loadBriefArticles = (data) => {
	return {
		type: BRIEFARTICLES_LOAD,
		payload: data
	};
}


export const loadBriefArticlesSuccess = (data) => {
	return {
        type: BRIEFARTICLES_LOAD_SUCCESS,
        payload: data
	};
}


export const loadBriefArticlesFailed = () => {
	return {
		type: BRIEFARTICLES_LOAD_FAILED
	};
}


export const loadBriefArticle = () => {
	return {
		type: BRIEFARTICLE_LOAD
	};
}


export const loadBriefArticleSuccess = (data) => {
	return {
		type: BRIEFARTICLE_LOAD_SUCCESS,
		payload: data
	};
}


export const loadBriefArticleFailed = () => {
	return {
		type: BRIEFARTICLE_LOAD_FAILED
	};
}


export const addBriefArticle = (data) => {
	return {
		type: BRIEFARTICLE_ADD,
		payload: data
	};
}


export const addBriefArticleSuccess = (filter) => {
	return {
		type: BRIEFARTICLE_ADD_SUCCESS,
		message: 'BriefArticle added successfully.',
		payload: filter
	};
}


export const addBriefArticleFailed = () => {
	return {
		type: BRIEFARTICLE_ADD_FAILED
	};
}


export const updateBriefArticle = (data) => {
	return {
		type: BRIEFARTICLE_UPDATE,
		payload: data
	};
}


export const updateBriefArticleSuccess = (data) => {
	return {
		type: BRIEFARTICLE_UPDATE_SUCCESS,
		payload: data
	};
}


export const updateBriefArticleFailed = (data) => {
	return {
		type: BRIEFARTICLE_UPDATE_FAILED,
		payload: data
	};
}


export const deleteBriefArticle = (data) => {
	return {
		type: BRIEFARTICLE_DELETE,
		payload: data
	};
}


export const deleteBriefArticleSuccess = () => {
	return {
		type: BRIEFARTICLE_DELETE_SUCCESS,
		message: 'BriefArticle deleted successfully.'
	};
}


export const deleteBriefArticleFailed = () => {
	return {
		type: BRIEFARTICLE_DELETE_FAILED
	};
}

export const deleteAllBriefArticles = (data) => {
	return {
		type: BRIEFARTICLE_DELETE_ALL,
		payload: data
	};
}


export const deleteAllBriefArticlesSuccess = () => {
	return {
		type: BRIEFARTICLE_DELETE_ALL_SUCCESS,
		message: 'BriefArticle all deleted successfully.'
	};
}


export const deleteAllBriefArticlesFailed = () => {
	return {
		type: BRIEFARTICLE_DELETE_ALL_FAILED
	};
}