import {
	MENUS_SELECT_ALL,
	MENUS_UNSELECT_ALL,
	MENU_SELECTED,
	MENUS_LOAD,
	MENUS_LOAD_SUCCESS,
  MENUS_LOAD_FAILED,
  MENU_LOAD,
	MENU_LOAD_SUCCESS,
	MENU_LOAD_FAILED,
	MENU_ADD,
	MENU_ADD_SUCCESS,
	MENU_ADD_FAILED,
	MENU_UPDATE,
	MENU_UPDATE_SUCCESS,
	MENU_UPDATE_FAILED,
	MENU_DELETE,
	MENU_DELETE_SUCCESS,
	MENU_DELETE_FAILED,
	MENU_DELETE_ALL,
	MENU_DELETE_ALL_SUCCESS,
	MENU_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

export const selectAllMenus = () => {
	return {
		type: MENUS_SELECT_ALL
	}
}

export const unselectAllMenus = () => {
	return {
		type: MENUS_UNSELECT_ALL
	}
}

export const selectMenu = data => {
	return {
		type: MENU_SELECTED,
		payload: data
	}
}

export const loadMenus = (data) => {
	return {
		type: MENUS_LOAD,
		payload: data
	};
}


export const loadMenusSuccess = (data) => {
	return {
        type: MENUS_LOAD_SUCCESS,
        payload: data
	};
}


export const loadMenusFailed = () => {
	return {
		type: MENUS_LOAD_FAILED
	};
}


export const loadMenu = () => {
	return {
		type: MENU_LOAD
	};
}


export const loadMenuSuccess = (data) => {
	return {
		type: MENU_LOAD_SUCCESS,
		payload: data
	};
}


export const loadMenuFailed = () => {
	return {
		type: MENU_LOAD_FAILED
	};
}


export const addMenu = (data) => {
	return {
		type: MENU_ADD,
		payload: data
	};
}


export const addMenuSuccess = (filter) => {
	return {
		type: MENU_ADD_SUCCESS,
		message: 'Menu added successfully.',
		payload: filter
	};
}


export const addMenuFailed = () => {
	return {
		type: MENU_ADD_FAILED
	};
}


export const updateMenu = (data) => {
	return {
		type: MENU_UPDATE,
		payload: data
	};
}


export const updateMenuSuccess = (data) => {
	return {
		type: MENU_UPDATE_SUCCESS,
		payload: data
	};
}


export const updateMenuFailed = (data) => {
	return {
		type: MENU_UPDATE_FAILED,
		payload: data
	};
}


export const deleteMenu = (data) => {
	return {
		type: MENU_DELETE,
		payload: data
	};
}


export const deleteMenuSuccess = () => {
	return {
		type: MENU_DELETE_SUCCESS,
		message: 'Menu deleted successfully.'
	};
}


export const deleteMenuFailed = () => {
	return {
		type: MENU_DELETE_FAILED
	};
}

export const deleteAllMenus = (data) => {
	return {
		type: MENU_DELETE_ALL,
		payload: data
	};
}


export const deleteAllMenusSuccess = () => {
	return {
		type: MENU_DELETE_ALL_SUCCESS,
		message: 'Menu all deleted successfully.'
	};
}


export const deleteAllMenusFailed = () => {
	return {
		type: MENU_DELETE_ALL_FAILED
	};
}

