import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadImages as loadImagesAction,
	loadImageSuccess,
	loadImagesSuccess,
	addImageSuccess,
	updateImageSuccess,
    deleteImageSuccess,
    deleteAllImagesSuccess
} from '../actions/Image';
import {
	loadImagesFailed,
	loadImageFailed,
	addImageFailed,
	updateImageFailed,
	deleteImageFailed
} from '../actions/Image';


import {
	IMAGES_LOAD,
	IMAGE_LOAD,
	IMAGE_ADD,
	IMAGE_UPDATE,
    IMAGE_DELETE,
    IMAGE_DELETE_ALL,
    IMAGE_DELETE_ALL_SUCCESS,
    IMAGE_DELETE_ALL_FAILED,
    IMAGE_ADD_SUCCESS,
    IMAGE_UPDATE_SUCCESS,
} from '../../constants/ActionTypes';

import {
    userSignOut
} from '../actions/Auth';

const loadImagesRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`albums/${localStorage.selectedAlbumId}/images/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`albums/${localStorage.selectedAlbumId}/images`, { params })
        .then(data => data)
        .catch(error => error);
}
    

const loadImageRequest = async (id) =>
    await Api().get(`albums/${localStorage.selectedAlbumId}/images/${id}`)
        .then(data => data)
        .catch(error => error);

const addImageRequest = async (payload) =>
    await Api().post(`albums/${localStorage.selectedAlbumId}/images`, payload)
      .then(data => data)
      .catch(error => error);

const updateImageRequest = async (payload) =>
    await Api().patch(`albums/${localStorage.selectedAlbumId}/images/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deleteImageRequest = async (id) =>
    await Api().delete(`albums/${localStorage.selectedAlbumId}/images/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadImages({payload}) {
    try {
        const data = yield call(loadImagesRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadImagesSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshImages({payload}) {
    yield loadImages(payload);
}

function* processLoadImages() {
    yield takeEvery(IMAGES_LOAD, loadImages);
}

function* loadImage({selectedImageId}) {
    try {
        const data = yield call(loadImageRequest, selectedImageId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadImageSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadImage() {
    yield takeEvery(IMAGE_LOAD, loadImage);
}


function* addImage({payload}) {
    try {
        const data = yield call(addImageRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadImagesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddImage() {
    yield takeEvery(IMAGE_ADD, addImage);
}


function* updateImage({payload}) {
    try {
        const data = yield call(updateImageRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadImagesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateImage() {
    yield takeEvery(IMAGE_UPDATE, updateImage);
}

function* deleteImage({payload}) {
    try {
        const data = yield call(deleteImageRequest, payload.model.id);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadImagesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllImages({payload}) {
    try {        
        for(let i = 0; i < payload.models.length; ++i){
            let data = yield call(deleteImageRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }
        
        yield put(loadImagesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteImage() {
    yield takeEvery(IMAGE_DELETE, deleteImage);
}

function* processDeleteAllImages() {
    yield takeEvery(IMAGE_DELETE_ALL, deleteAllImages);
}

export default function* contactSagas() {
    yield all([fork(processLoadImages),
      fork(processLoadImage),
      fork(processAddImage),
      fork(processUpdateImage),
      fork(processDeleteAllImages),
      fork(processDeleteImage)]);
}