import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadMenus as loadMenusAction,
	loadMenuSuccess,
	loadMenusSuccess,
	addMenuSuccess,
	updateMenuSuccess,
    deleteMenuSuccess,
    deleteAllMenusSuccess
} from '../actions/Menu';
import {
	loadMenusFailed,
	loadMenuFailed,
	addMenuFailed,
	updateMenuFailed,
	deleteMenuFailed
} from '../actions/Menu';


import {
	MENUS_LOAD,
	MENU_LOAD,
	MENU_ADD,
	MENU_UPDATE,
    MENU_DELETE,
    MENU_DELETE_ALL,
    MENU_DELETE_ALL_SUCCESS,
    MENU_DELETE_ALL_FAILED,
	PDF_LOAD,
	PDF_ADD,
	PDF_UPDATE,
	PDF_DELETE,
    MENU_ADD_SUCCESS,
    MENU_UPDATE_SUCCESS,
} from '../../constants/ActionTypes';

import {
    userSignOut
} from '../actions/Auth';

const loadMenusRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`menus/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`menus`, { params })
        .then(data => data)
        .catch(error => error);
}


const loadMenuRequest = async (id) =>
    await Api().get(`menus/${id}`)
        .then(data => data)
        .catch(error => error);

const addMenuRequest = async (payload) =>
    await Api().post(`menus`, payload)
      .then(data => data)
      .catch(error => error);

const updateMenuRequest = async (payload) =>
    await Api().patch(`menus/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deleteMenuRequest = async (id) =>
    await Api().delete(`menus/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadMenus({payload}) {
    try {
        const data = yield call(loadMenusRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadMenusSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshMenus({payload}) {
    yield loadMenus(payload);
}

function* processLoadMenus() {
    yield takeEvery(MENUS_LOAD, loadMenus);
}

function* loadMenu({selectedMenuId}) {
    try {
        const data = yield call(loadMenuRequest, selectedMenuId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadMenuSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadMenu() {
    yield takeEvery(MENU_LOAD, loadMenu);
}


function* addMenu({payload}) {
    try {
        const data = yield call(addMenuRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(addMenuSuccess(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddMenu() {
    yield takeEvery(MENU_ADD, addMenu);
}


function* updateMenu({payload}) {
    try {
        const data = yield call(updateMenuRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(updateMenuSuccess(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateMenu() {
    yield takeEvery(MENU_UPDATE, updateMenu);
}

function* deleteMenu({payload}) {
    try {
        const data = yield call(deleteMenuRequest, payload.model.id);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadMenusAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllMenus({payload}) {
    try {
        for(let i = 0; i < payload.models.length; ++i){
            let data = yield call(deleteMenuRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }

        yield put(loadMenusAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteMenu() {
    yield takeEvery(MENU_DELETE, deleteMenu);
}

function* processDeleteAllMenus() {
    yield takeEvery(MENU_DELETE_ALL, deleteAllMenus);
}

export default function* menuSagas() {
    yield all([fork(processLoadMenus),
      fork(processLoadMenu),
      fork(processAddMenu),
      fork(processUpdateMenu),
      fork(processDeleteAllMenus),
      fork(processDeleteMenu)]);
}
