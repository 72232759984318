import {
	ALBUMS_SELECT_ALL,
	ALBUMS_UNSELECT_ALL,
	ALBUM_SELECTED,
	ALBUMS_LOAD,
	ALBUMS_LOAD_SUCCESS,
    ALBUMS_LOAD_FAILED,
    ALBUM_LOAD,
	ALBUM_LOAD_SUCCESS,
	ALBUM_LOAD_FAILED,
	ALBUM_ADD,
	ALBUM_ADD_SUCCESS,
	ALBUM_ADD_FAILED,
	ALBUM_UPDATE,
	ALBUM_UPDATE_SUCCESS,
	ALBUM_UPDATE_FAILED,
	ALBUM_DELETE,
	ALBUM_DELETE_SUCCESS,
	ALBUM_DELETE_FAILED,
	ALBUM_DELETE_ALL,
	ALBUM_DELETE_ALL_SUCCESS,
	ALBUM_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
    albums: {
        records: [],
        offset: 0,
		limit: 50,
		totalRecords: 0
    },
    selectedAlbum: null,
    selectedAlbumId: null,
	message: null,
	messageType: 'info',
	albumFilter: {
		offset: 0,
		limit: 1000,
		orderBy: 'name',
		filterGroups: null
	  }
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case ALBUMS_SELECT_ALL:
			return {
				...state,
				albums: {
					...state.albums,
					records: state.albums.records.map(data => {
						data.selected = true;
						return data;
					})
				}
			}
		case ALBUMS_UNSELECT_ALL:
			return {
				...state,
				albums: {
					...state.albums,
					records: state.albums.records.map(data => {
						data.selected = false;
						return data;
					})
				}
			}
		case ALBUM_SELECTED: 
			return {
				...state,
				selectedAlbum: action.payload,
				selectedAlbumId: action.payload.id
			};
		case ALBUMS_LOAD: 
			return {
				...state,
				albumFilter: action.payload ? action.payload : state.albumFilter
			};
		case ALBUMS_LOAD_SUCCESS:
			return {
				...state,
				albums: action.payload
			};
		case ALBUMS_LOAD_FAILED:
			return {
				...state
            };
        case ALBUM_LOAD: 
			return {
				...state
			};
		case ALBUM_LOAD_SUCCESS:
			return {
				...state
			};
		case ALBUM_LOAD_FAILED:
			return {
				...state
			};
		case ALBUM_ADD:
			return {
				...state
			};
		case ALBUM_ADD_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case ALBUM_ADD_FAILED:
			return {
				...state
			};
		case ALBUM_UPDATE:
			return {
				...state
			};
		case ALBUM_UPDATE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case ALBUM_UPDATE_FAILED:
			return {
				...state
			};
		case ALBUM_DELETE:
			return {
				...state
			};
		case ALBUM_DELETE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case ALBUM_DELETE_FAILED:
			return {
				...state
			};
		case ALBUM_DELETE_ALL:
			return {
				...state
			};
		case ALBUM_DELETE_ALL_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case ALBUM_DELETE_ALL_FAILED:
			return {
				...state
			};
		default:
			return state;
	}
}