import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Album from "./Album";
import Project from "./Project";
import Product from "./Product";
import BriefArticle from "./BriefArticle";
import Introduction from "./Introduction";
import Contact from "./Contact";
import AboutUs from "./AboutUs";
import Image from "./Image";
import PdfAlbum from "./PdfAlbum";
import Pdf from "./Pdf";
import Statistic from "./Statistic";
import ProductType from "./ProductType";
import ServiceType from "./ServiceType";
import Menu from './Menu';

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  album: Album,
  project: Project,
  product: Product,
  briefArticle: BriefArticle,
  introduction: Introduction,
  contact: Contact,
  aboutUs: AboutUs,
  image: Image,
  pdfAlbum: PdfAlbum,
  pdf: Pdf,
  statistic: Statistic,
  productType: ProductType,
  serviceType: ServiceType,
  menu: Menu
});

export default reducers;
