import {
	PDFALBUMS_SELECT_ALL,
	PDFALBUMS_UNSELECT_ALL,
	PDFALBUM_SELECTED,
	PDFALBUMS_LOAD,
	PDFALBUMS_LOAD_SUCCESS,
    PDFALBUMS_LOAD_FAILED,
    PDFALBUM_LOAD,
	PDFALBUM_LOAD_SUCCESS,
	PDFALBUM_LOAD_FAILED,
	PDFALBUM_ADD,
	PDFALBUM_ADD_SUCCESS,
	PDFALBUM_ADD_FAILED,
	PDFALBUM_UPDATE,
	PDFALBUM_UPDATE_SUCCESS,
	PDFALBUM_UPDATE_FAILED,
	PDFALBUM_DELETE,
	PDFALBUM_DELETE_SUCCESS,
	PDFALBUM_DELETE_FAILED,
	PDFALBUM_DELETE_ALL,
	PDFALBUM_DELETE_ALL_SUCCESS,
	PDFALBUM_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

export const selectAllPdfAlbums = () => {
	return {
		type: PDFALBUMS_SELECT_ALL
	}
}

export const unselectAllPdfAlbums = () => {
	return {
		type: PDFALBUMS_UNSELECT_ALL
	}
}

export const selectPdfAlbum = data => {
	return {
		type: PDFALBUM_SELECTED,
		payload: data
	}
}

export const loadPdfAlbums = (data) => {
	return {
		type: PDFALBUMS_LOAD,
		payload: data
	};
}


export const loadPdfAlbumsSuccess = (data) => {
	return {
        type: PDFALBUMS_LOAD_SUCCESS,
        payload: data
	};
}


export const loadPdfAlbumsFailed = () => {
	return {
		type: PDFALBUMS_LOAD_FAILED
	};
}


export const loadPdfAlbum = () => {
	return {
		type: PDFALBUM_LOAD
	};
}


export const loadPdfAlbumSuccess = (data) => {
	return {
		type: PDFALBUM_LOAD_SUCCESS,
		payload: data
	};
}


export const loadPdfAlbumFailed = () => {
	return {
		type: PDFALBUM_LOAD_FAILED
	};
}


export const addPdfAlbum = (data) => {
	return {
		type: PDFALBUM_ADD,
		payload: data
	};
}


export const addPdfAlbumSuccess = (filter) => {
	return {
		type: PDFALBUM_ADD_SUCCESS,
		message: 'PdfAlbum added successfully.',
		payload: filter
	};
}


export const addPdfAlbumFailed = () => {
	return {
		type: PDFALBUM_ADD_FAILED
	};
}


export const updatePdfAlbum = (data) => {
	return {
		type: PDFALBUM_UPDATE,
		payload: data
	};
}


export const updatePdfAlbumSuccess = (data) => {
	return {
		type: PDFALBUM_UPDATE_SUCCESS,
		payload: data
	};
}


export const updatePdfAlbumFailed = (data) => {
	return {
		type: PDFALBUM_UPDATE_FAILED,
		payload: data
	};
}


export const deletePdfAlbum = (data) => {
	return {
		type: PDFALBUM_DELETE,
		payload: data
	};
}


export const deletePdfAlbumSuccess = () => {
	return {
		type: PDFALBUM_DELETE_SUCCESS,
		message: 'PdfAlbum deleted successfully.'
	};
}


export const deletePdfAlbumFailed = () => {
	return {
		type: PDFALBUM_DELETE_FAILED
	};
}

export const deleteAllPdfAlbums = (data) => {
	return {
		type: PDFALBUM_DELETE_ALL,
		payload: data
	};
}


export const deleteAllPdfAlbumsSuccess = () => {
	return {
		type: PDFALBUM_DELETE_ALL_SUCCESS,
		message: 'PdfAlbum all deleted successfully.'
	};
}


export const deleteAllPdfAlbumsFailed = () => {
	return {
		type: PDFALBUM_DELETE_ALL_FAILED
	};
}

