import {
	STATISTICS_SELECT_ALL,
	STATISTICS_UNSELECT_ALL,
	STATISTIC_SELECTED,
	STATISTICS_LOAD,
	STATISTICS_LOAD_SUCCESS,
    STATISTICS_LOAD_FAILED,
    STATISTIC_LOAD,
	STATISTIC_LOAD_SUCCESS,
	STATISTIC_LOAD_FAILED,
	STATISTIC_ADD,
	STATISTIC_ADD_SUCCESS,
	STATISTIC_ADD_FAILED,
	STATISTIC_UPDATE,
	STATISTIC_UPDATE_SUCCESS,
	STATISTIC_UPDATE_FAILED,
	STATISTIC_DELETE,
	STATISTIC_DELETE_SUCCESS,
	STATISTIC_DELETE_FAILED,
	STATISTIC_DELETE_ALL,
	STATISTIC_DELETE_ALL_SUCCESS,
	STATISTIC_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

export const selectAllStatistics = () => {
	return {
		type: STATISTICS_SELECT_ALL
	}
}

export const unselectAllStatistics = () => {
	return {
		type: STATISTICS_UNSELECT_ALL
	}
}

export const selectStatistic = data => {
	return {
		type: STATISTIC_SELECTED,
		payload: data
	}
}

export const loadStatistics = (data) => {
	return {
		type: STATISTICS_LOAD,
		payload: data
	};
}


export const loadStatisticsSuccess = (data) => {
	return {
        type: STATISTICS_LOAD_SUCCESS,
        payload: data
	};
}


export const loadStatisticsFailed = () => {
	return {
		type: STATISTICS_LOAD_FAILED
	};
}


export const loadStatistic = () => {
	return {
		type: STATISTIC_LOAD
	};
}


export const loadStatisticSuccess = (data) => {
	return {
		type: STATISTIC_LOAD_SUCCESS,
		payload: data
	};
}


export const loadStatisticFailed = () => {
	return {
		type: STATISTIC_LOAD_FAILED
	};
}


export const addStatistic = (data) => {
	return {
		type: STATISTIC_ADD,
		payload: data
	};
}


export const addStatisticSuccess = (filter) => {
	return {
		type: STATISTIC_ADD_SUCCESS,
		message: 'Statistic added successfully.',
		payload: filter
	};
}


export const addStatisticFailed = () => {
	return {
		type: STATISTIC_ADD_FAILED
	};
}


export const updateStatistic = (data) => {
	return {
		type: STATISTIC_UPDATE,
		payload: data
	};
}


export const updateStatisticSuccess = (data) => {
	return {
		type: STATISTIC_UPDATE_SUCCESS,
		payload: data
	};
}


export const updateStatisticFailed = (data) => {
	return {
		type: STATISTIC_UPDATE_FAILED,
		payload: data
	};
}


export const deleteStatistic = (data) => {
	return {
		type: STATISTIC_DELETE,
		payload: data
	};
}


export const deleteStatisticSuccess = () => {
	return {
		type: STATISTIC_DELETE_SUCCESS,
		message: 'Statistic deleted successfully.'
	};
}


export const deleteStatisticFailed = () => {
	return {
		type: STATISTIC_DELETE_FAILED
	};
}

export const deleteAllStatistics = (data) => {
	return {
		type: STATISTIC_DELETE_ALL,
		payload: data
	};
}


export const deleteAllStatisticsSuccess = () => {
	return {
		type: STATISTIC_DELETE_ALL_SUCCESS,
		message: 'Statistic all deleted successfully.'
	};
}


export const deleteAllStatisticsFailed = () => {
	return {
		type: STATISTIC_DELETE_ALL_FAILED
	};
}