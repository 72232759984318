import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadProjects as loadProjectsAction,
	loadProjectSuccess,
	loadProjectsSuccess,
	addProjectSuccess,
	updateProjectSuccess,
    deleteProjectSuccess,
    deleteAllProjectsSuccess
} from '../actions/Project';
import {
	loadProjectsFailed,
	loadProjectFailed,
	addProjectFailed,
	updateProjectFailed,
	deleteProjectFailed
} from '../actions/Project';


import {
	PROJECTS_LOAD,
	PROJECT_LOAD,
	PROJECT_ADD,
	PROJECT_UPDATE,
    PROJECT_DELETE,
    PROJECT_DELETE_ALL,
    PROJECT_DELETE_ALL_SUCCESS,
    PROJECT_DELETE_ALL_FAILED,
	IMAGE_LOAD,
	IMAGE_ADD,
	IMAGE_UPDATE,
	IMAGE_DELETE,
    PROJECT_ADD_SUCCESS,
    PROJECT_UPDATE_SUCCESS,
} from '../../constants/ActionTypes';

import {
    userSignOut
} from '../actions/Auth';

const loadProjectsRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`projects/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`projects`, { params })
        .then(data => data)
        .catch(error => error);
}
    

const loadProjectRequest = async (id) =>
    await Api().get(`projects/${id}`)
        .then(data => data)
        .catch(error => error);

const addProjectRequest = async (payload) =>
    await Api().post(`projects`, payload)
      .then(data => data)
      .catch(error => error);

const updateProjectRequest = async (payload) =>
    await Api().patch(`projects/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deleteProjectRequest = async (id) =>
    await Api().delete(`projects/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadProjects({payload}) {
    try {
        const data = yield call(loadProjectsRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProjectsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshProjects({payload}) {
    yield loadProjects(payload);
}

function* processLoadProjects() {
    yield takeEvery(PROJECTS_LOAD, loadProjects);
}

function* loadProject({selectedProjectId}) {
    try {
        const data = yield call(loadProjectRequest, selectedProjectId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProjectSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadProject() {
    yield takeEvery(PROJECT_LOAD, loadProject);
}


function* addProject({payload}) {
    try {
        const data = yield call(addProjectRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProjectsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddProject() {
    yield takeEvery(PROJECT_ADD, addProject);
}


function* updateProject({payload}) {
    try {
        const data = yield call(updateProjectRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProjectsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateProject() {
    yield takeEvery(PROJECT_UPDATE, updateProject);
}

function* deleteProject({payload}) {
    try {
        const data = yield call(deleteProjectRequest, payload.model.id);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadProjectsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllProjects({payload}) {
    try {        
        for(let i = 0; i < payload.models.length; ++i){
            let data = yield call(deleteProjectRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }
        
        yield put(loadProjectsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteProject() {
    yield takeEvery(PROJECT_DELETE, deleteProject);
}

function* processDeleteAllProjects() {
    yield takeEvery(PROJECT_DELETE_ALL, deleteAllProjects);
}

export default function* projectSagas() {
    yield all([fork(processLoadProjects),
      fork(processLoadProject),
      fork(processAddProject),
      fork(processUpdateProject),
      fork(processDeleteAllProjects),
      fork(processDeleteProject)]);
}