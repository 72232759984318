import {
	PRODUCT_TYPES_SELECT_ALL,
	PRODUCT_TYPES_UNSELECT_ALL,
	PRODUCT_TYPE_SELECTED,
	PRODUCT_TYPES_LOAD,
	PRODUCT_TYPES_LOAD_SUCCESS,
  PRODUCT_TYPES_LOAD_FAILED,
  PRODUCT_TYPE_LOAD,
	PRODUCT_TYPE_LOAD_SUCCESS,
	PRODUCT_TYPE_LOAD_FAILED,
	PRODUCT_TYPE_ADD,
	PRODUCT_TYPE_ADD_SUCCESS,
	PRODUCT_TYPE_ADD_FAILED,
	PRODUCT_TYPE_UPDATE,
	PRODUCT_TYPE_UPDATE_SUCCESS,
	PRODUCT_TYPE_UPDATE_FAILED,
	PRODUCT_TYPE_DELETE,
	PRODUCT_TYPE_DELETE_SUCCESS,
	PRODUCT_TYPE_DELETE_FAILED,
	PRODUCT_TYPE_DELETE_ALL,
	PRODUCT_TYPE_DELETE_ALL_SUCCESS,
	PRODUCT_TYPE_DELETE_ALL_FAILED,
	PRODUCT_TYPE_RESTORE,
	PRODUCT_TYPE_RESTORE_SUCCESS,
	PRODUCT_TYPE_RESTORE_FAILED,
} from '../../constants/ActionTypes';

export const selectAllProductTypes = () => {
	return {
		type: PRODUCT_TYPES_SELECT_ALL
	}
}

export const unselectAllProductTypes = () => {
	return {
		type: PRODUCT_TYPES_UNSELECT_ALL
	}
}

export const selectProductType = data => {
	return {
		type: PRODUCT_TYPE_SELECTED,
		payload: data
	}
}

export const loadProductTypes = (data) => {
	return {
		type: PRODUCT_TYPES_LOAD,
		payload: data
	};
}


export const loadProductTypesSuccess = (data) => {
	return {
        type: PRODUCT_TYPES_LOAD_SUCCESS,
        payload: data
	};
}


export const loadProductTypesFailed = () => {
	return {
		type: PRODUCT_TYPES_LOAD_FAILED
	};
}


export const loadProductType = () => {
	return {
		type: PRODUCT_TYPE_LOAD
	};
}


export const loadProductTypeSuccess = (data) => {
	return {
		type: PRODUCT_TYPE_LOAD_SUCCESS,
		payload: data
	};
}


export const loadProductTypeFailed = () => {
	return {
		type: PRODUCT_TYPE_LOAD_FAILED
	};
}


export const addProductType = (data) => {
	return {
		type: PRODUCT_TYPE_ADD,
		payload: data
	};
}


export const addProductTypeSuccess = (filter) => {
	return {
		type: PRODUCT_TYPE_ADD_SUCCESS,
		message: 'ProductType added successfully.',
		payload: filter
	};
}


export const addProductTypeFailed = () => {
	return {
		type: PRODUCT_TYPE_ADD_FAILED
	};
}


export const updateProductType = (data) => {
	return {
		type: PRODUCT_TYPE_UPDATE,
		payload: data
	};
}


export const updateProductTypeSuccess = (data) => {
	return {
		type: PRODUCT_TYPE_UPDATE_SUCCESS,
		payload: data
	};
}


export const updateProductTypeFailed = (data) => {
	return {
		type: PRODUCT_TYPE_UPDATE_FAILED,
		payload: data
	};
}


export const deleteProductType = (data) => {
	return {
		type: PRODUCT_TYPE_DELETE,
		payload: data
	};
}


export const deleteProductTypeSuccess = () => {
	return {
		type: PRODUCT_TYPE_DELETE_SUCCESS,
		message: 'ProductType deleted successfully.'
	};
}


export const deleteProductTypeFailed = () => {
	return {
		type: PRODUCT_TYPE_DELETE_FAILED
	};
}

export const deleteAllProductTypes = (data) => {
	return {
		type: PRODUCT_TYPE_DELETE_ALL,
		payload: data
	};
}


export const deleteAllProductTypesSuccess = () => {
	return {
		type: PRODUCT_TYPE_DELETE_ALL_SUCCESS,
		message: 'ProductType all deleted successfully.'
	};
}


export const deleteAllProductTypesFailed = () => {
	return {
		type: PRODUCT_TYPE_DELETE_ALL_FAILED
	};
}

export const restoreProductType = (data) => {
	return {
		type: PRODUCT_TYPE_RESTORE,
		payload: data
	};
}


export const restoreProductTypeSuccess = () => {
	return {
		type: PRODUCT_TYPE_RESTORE_SUCCESS,
		message: 'ProductType restored successfully.'
	};
}


export const restoreProductTypeFailed = () => {
	return {
		type: PRODUCT_TYPE_RESTORE_FAILED
	};
}
