import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadAboutUses as loadAboutUsesAction,
	loadAboutUsSuccess,
	loadAboutUsesSuccess,
	addAboutUsSuccess,
	updateAboutUsSuccess,
    deleteAboutUsSuccess,
    deleteAllAboutUsesSuccess
} from '../actions/AboutUs';
import {
	loadAboutUsesFailed,
	loadAboutUsFailed,
	addAboutUsFailed,
	updateAboutUsFailed,
	deleteAboutUsFailed
} from '../actions/AboutUs';


import {
	ABOUTUSES_LOAD,
	ABOUTUS_LOAD,
	ABOUTUS_ADD,
	ABOUTUS_UPDATE,
    ABOUTUS_DELETE,
    ABOUTUS_DELETE_ALL,
    ABOUTUS_DELETE_ALL_SUCCESS,
    ABOUTUS_DELETE_ALL_FAILED,
	IMAGE_LOAD,
	IMAGE_ADD,
	IMAGE_UPDATE,
	IMAGE_DELETE,
    ABOUTUS_ADD_SUCCESS,
    ABOUTUS_UPDATE_SUCCESS,
} from '../../constants/ActionTypes';

import {
    userSignOut
} from '../actions/Auth';

const loadAboutUsesRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`about-us/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`about-us`, { params })
        .then(data => data)
        .catch(error => error);
}
    

const loadAboutUsRequest = async (id) =>
    await Api().get(`about-us/${id}`)
        .then(data => data)
        .catch(error => error);

const addAboutUsRequest = async (payload) =>
    await Api().post(`about-us`, payload)
      .then(data => data)
      .catch(error => error);

const updateAboutUsRequest = async (payload) =>
    await Api().patch(`about-us/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deleteAboutUsRequest = async (id) =>
    await Api().delete(`about-us/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadAboutUses({payload}) {
    try {
        const data = yield call(loadAboutUsesRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadAboutUsesSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshAboutUses({payload}) {
    yield loadAboutUses(payload);
}

function* processLoadAboutUses() {
    yield takeEvery(ABOUTUSES_LOAD, loadAboutUses);
}

function* loadAboutUs({selectedAboutUsId}) {
    try {
        const data = yield call(loadAboutUsRequest, selectedAboutUsId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadAboutUsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadAboutUs() {
    yield takeEvery(ABOUTUS_LOAD, loadAboutUs);
}


function* addAboutUs({payload}) {
    try {
        const data = yield call(addAboutUsRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadAboutUsesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddAboutUs() {
    yield takeEvery(ABOUTUS_ADD, addAboutUs);
}


function* updateAboutUs({payload}) {
    try {
        const data = yield call(updateAboutUsRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadAboutUsesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateAboutUs() {
    yield takeEvery(ABOUTUS_UPDATE, updateAboutUs);
}

function* deleteAboutUs({payload}) {
    try {
        const data = yield call(deleteAboutUsRequest, payload.model.id);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadAboutUsesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllAboutUses({payload}) {
    try {        
        for(let i = 0; i < payload.models.length; ++i){
            const data = yield call(deleteAboutUsRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }
        
        yield put(loadAboutUsesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteAboutUs() {
    yield takeEvery(ABOUTUS_DELETE, deleteAboutUs);
}

function* processDeleteAllAboutUses() {
    yield takeEvery(ABOUTUS_DELETE_ALL, deleteAllAboutUses);
}

export default function* aboutUsSagas() {
    yield all([fork(processLoadAboutUses),
      fork(processLoadAboutUs),
      fork(processAddAboutUs),
      fork(processUpdateAboutUs),
      fork(processDeleteAllAboutUses),
      fork(processDeleteAboutUs)]);
}