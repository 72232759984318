import {
	ABOUTUSES_SELECT_ALL,
	ABOUTUSES_UNSELECT_ALL,
	ABOUTUS_SELECTED,
	ABOUTUSES_LOAD,
	ABOUTUSES_LOAD_SUCCESS,
    ABOUTUSES_LOAD_FAILED,
    ABOUTUS_LOAD,
	ABOUTUS_LOAD_SUCCESS,
	ABOUTUS_LOAD_FAILED,
	ABOUTUS_ADD,
	ABOUTUS_ADD_SUCCESS,
	ABOUTUS_ADD_FAILED,
	ABOUTUS_UPDATE,
	ABOUTUS_UPDATE_SUCCESS,
	ABOUTUS_UPDATE_FAILED,
	ABOUTUS_DELETE,
	ABOUTUS_DELETE_SUCCESS,
	ABOUTUS_DELETE_FAILED,
	ABOUTUS_DELETE_ALL,
	ABOUTUS_DELETE_ALL_SUCCESS,
	ABOUTUS_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
    aboutUses: {
        records: [],
        offset: 0,
		limit: 1000,
		totalRecords: 0
    },
    selectedAboutUs: null,
    selectedAboutUsId: null,
	selectedImage: null,
	selectedImageId: null,
	message: null,
	messageType: 'info',
	aboutUsFilter: {
		offset: 0,
		limit: 1000,
		orderBy: 'name',
		filterGroups: null
	  }
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case ABOUTUSES_SELECT_ALL:
			return {
				...state,
				aboutUses: {
					...state.aboutUses,
					records: state.aboutUses.records.map(data => {
						data.selected = true;
						return data;
					})
				}
			}
		case ABOUTUSES_UNSELECT_ALL:
			return {
				...state,
				aboutUses: {
					...state.aboutUses,
					records: state.aboutUses.records.map(data => {
						data.selected = false;
						return data;
					})
				}
			}
		case ABOUTUS_SELECTED: 
			return {
				...state,
				selectedAboutUs: action.payload,
				selectedAboutUsId: action.payload.id
			};
		case ABOUTUSES_LOAD: 
			return {
				...state,
				aboutUsFilter: action.payload ? action.payload : state.aboutUsFilter
			};
		case ABOUTUSES_LOAD_SUCCESS:
			return {
				...state,
				aboutUses: action.payload
			};
		case ABOUTUSES_LOAD_FAILED:
			return {
				...state
            };
        case ABOUTUS_LOAD: 
			return {
				...state
			};
		case ABOUTUS_LOAD_SUCCESS:
			return {
				...state
			};
		case ABOUTUS_LOAD_FAILED:
			return {
				...state
			};
		case ABOUTUS_ADD:
			return {
				...state
			};
		case ABOUTUS_ADD_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case ABOUTUS_ADD_FAILED:
			return {
				...state
			};
		case ABOUTUS_UPDATE:
			return {
				...state
			};
		case ABOUTUS_UPDATE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case ABOUTUS_UPDATE_FAILED:
			return {
				...state
			};
		case ABOUTUS_DELETE:
			return {
				...state
			};
		case ABOUTUS_DELETE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case ABOUTUS_DELETE_FAILED:
			return {
				...state
			};
		case ABOUTUS_DELETE_ALL:
			return {
				...state
			};
		case ABOUTUS_DELETE_ALL_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case ABOUTUS_DELETE_ALL_FAILED:
			return {
				...state
			};
		default:
			return state;
	}
}