import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadStatistics as loadStatisticsAction,
	loadStatisticSuccess,
	loadStatisticsSuccess,
	addStatisticSuccess,
	updateStatisticSuccess,
    deleteStatisticSuccess,
    deleteAllStatisticsSuccess
} from '../actions/Statistic';
import {
	loadStatisticsFailed,
	loadStatisticFailed,
	addStatisticFailed,
	updateStatisticFailed,
	deleteStatisticFailed
} from '../actions/Statistic';


import {
	STATISTICS_LOAD,
	STATISTIC_LOAD,
	STATISTIC_ADD,
	STATISTIC_UPDATE,
    STATISTIC_DELETE,
    STATISTIC_DELETE_ALL,
    STATISTIC_DELETE_ALL_SUCCESS,
    STATISTIC_DELETE_ALL_FAILED,
	IMAGE_LOAD,
	IMAGE_ADD,
	IMAGE_UPDATE,
	IMAGE_DELETE,
    STATISTIC_ADD_SUCCESS,
    STATISTIC_UPDATE_SUCCESS,
} from '../../constants/ActionTypes';

import {
    userSignOut
} from '../actions/Auth';

const loadStatisticsRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`statistics/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`statistics`, { params })
        .then(data => data)
        .catch(error => error);
}
    

const loadStatisticRequest = async (id) =>
    await Api().get(`statistics/${id}`)
        .then(data => data)
        .catch(error => error);

const addStatisticRequest = async (payload) =>
    await Api().post(`statistics`, payload)
      .then(data => data)
      .catch(error => error);

const updateStatisticRequest = async (payload) =>
    await Api().patch(`statistics/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deleteStatisticRequest = async (id) =>
    await Api().delete(`statistics/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadStatistics({payload}) {
    try {
        const data = yield call(loadStatisticsRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadStatisticsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshStatistics({payload}) {
    yield loadStatistics(payload);
}

function* processLoadStatistics() {
    yield takeEvery(STATISTICS_LOAD, loadStatistics);
}

function* loadStatistic({selectedStatisticId}) {
    try {
        const data = yield call(loadStatisticRequest, selectedStatisticId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadStatisticSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadStatistic() {
    yield takeEvery(STATISTIC_LOAD, loadStatistic);
}


function* addStatistic({payload}) {
    try {
        const data = yield call(addStatisticRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadStatisticsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddStatistic() {
    yield takeEvery(STATISTIC_ADD, addStatistic);
}


function* updateStatistic({payload}) {
    try {
        const data = yield call(updateStatisticRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadStatisticsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateStatistic() {
    yield takeEvery(STATISTIC_UPDATE, updateStatistic);
}

function* deleteStatistic({payload}) {
    try {
        const data = yield call(deleteStatisticRequest, payload.model.id);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadStatisticsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllStatistics({payload}) {
    try {        
        for(let i = 0; i < payload.models.length; ++i){
            let data = yield call(deleteStatisticRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }
        
        yield put(loadStatisticsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteStatistic() {
    yield takeEvery(STATISTIC_DELETE, deleteStatistic);
}

function* processDeleteAllStatistics() {
    yield takeEvery(STATISTIC_DELETE_ALL, deleteAllStatistics);
}

export default function* statisticSagas() {
    yield all([fork(processLoadStatistics),
      fork(processLoadStatistic),
      fork(processAddStatistic),
      fork(processUpdateStatistic),
      fork(processDeleteAllStatistics),
      fork(processDeleteStatistic)]);
}
