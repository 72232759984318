import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadPdfs as loadPdfsAction,
	loadPdfSuccess,
	loadPdfsSuccess,
	addPdfSuccess,
	updatePdfSuccess,
    deletePdfSuccess,
    deleteAllPdfsSuccess
} from '../actions/Pdf';
import {
	loadPdfsFailed,
	loadPdfFailed,
	addPdfFailed,
	updatePdfFailed,
	deletePdfFailed
} from '../actions/Pdf';


import {
	PDFS_LOAD,
	PDF_LOAD,
	PDF_ADD,
	PDF_UPDATE,
    PDF_DELETE,
    PDF_DELETE_ALL,
    PDF_DELETE_ALL_SUCCESS,
    PDF_DELETE_ALL_FAILED,
    PDF_ADD_SUCCESS,
    PDF_UPDATE_SUCCESS,
} from '../../constants/ActionTypes';

import {
    userSignOut
} from '../actions/Auth';

const loadPdfsRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`pdf-albums/${localStorage.selectedPdfAlbumId}/pdfs/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`pdf-albums/${localStorage.selectedPdfAlbumId}/pdfs`, { params })
        .then(data => data)
        .catch(error => error);
}
    

const loadPdfRequest = async (id) =>
    await Api().get(`pdf-albums/${localStorage.selectedPdfAlbumId}/pdfs/${id}`)
        .then(data => data)
        .catch(error => error);

const addPdfRequest = async (payload) =>
    await Api().post(`pdf-albums/${localStorage.selectedPdfAlbumId}/pdfs`, payload)
      .then(data => data)
      .catch(error => error);

const updatePdfRequest = async (payload) =>
    await Api().patch(`pdf-albums/${localStorage.selectedPdfAlbumId}/pdfs/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deletePdfRequest = async (id) =>
    await Api().delete(`pdf-albums/${localStorage.selectedPdfAlbumId}/pdfs/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadPdfs({payload}) {
    try {
        const data = yield call(loadPdfsRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadPdfsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshPdfs({payload}) {
    yield loadPdfs(payload);
}

function* processLoadPdfs() {
    yield takeEvery(PDFS_LOAD, loadPdfs);
}

function* loadPdf({selectedPdfId}) {
    try {
        const data = yield call(loadPdfRequest, selectedPdfId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadPdfSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadPdf() {
    yield takeEvery(PDF_LOAD, loadPdf);
}


function* addPdf({payload}) {
    try {
        const data = yield call(addPdfRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadPdfsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddPdf() {
    yield takeEvery(PDF_ADD, addPdf);
}


function* updatePdf({payload}) {
    try {
        const data = yield call(updatePdfRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadPdfsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdatePdf() {
    yield takeEvery(PDF_UPDATE, updatePdf);
}

function* deletePdf({payload}) {
    try {
        const data = yield call(deletePdfRequest, payload.model.id);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadPdfsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllPdfs({payload}) {
    try {        
        for(let i = 0; i < payload.models.length; ++i){
            let data = yield call(deletePdfRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }
        
        yield put(loadPdfsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeletePdf() {
    yield takeEvery(PDF_DELETE, deletePdf);
}

function* processDeleteAllPdfs() {
    yield takeEvery(PDF_DELETE_ALL, deleteAllPdfs);
}

export default function* contactSagas() {
    yield all([fork(processLoadPdfs),
      fork(processLoadPdf),
      fork(processAddPdf),
      fork(processUpdatePdf),
      fork(processDeleteAllPdfs),
      fork(processDeletePdf)]);
}