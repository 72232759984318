import {
	PROJECTS_SELECT_ALL,
	PROJECTS_UNSELECT_ALL,
	PROJECT_SELECTED,
	PROJECTS_LOAD,
	PROJECTS_LOAD_SUCCESS,
    PROJECTS_LOAD_FAILED,
    PROJECT_LOAD,
	PROJECT_LOAD_SUCCESS,
	PROJECT_LOAD_FAILED,
	PROJECT_ADD,
	PROJECT_ADD_SUCCESS,
	PROJECT_ADD_FAILED,
	PROJECT_UPDATE,
	PROJECT_UPDATE_SUCCESS,
	PROJECT_UPDATE_FAILED,
	PROJECT_DELETE,
	PROJECT_DELETE_SUCCESS,
	PROJECT_DELETE_FAILED,
	PROJECT_DELETE_ALL,
	PROJECT_DELETE_ALL_SUCCESS,
	PROJECT_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
    projects: {
        records: [],
        offset: 0,
		limit: 1000,
		totalRecords: 0
    },
    selectedProject: null,
    selectedProjectId: null,
	selectedImage: null,
	selectedImageId: null,
	message: null,
	messageType: 'info',
	projectFilter: {
		offset: 0,
		limit: 1000,
		orderBy: 'name',
		filterGroups: null
	  }
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case PROJECTS_SELECT_ALL:
			return {
				...state,
				projects: {
					...state.projects,
					records: state.projects.records.map(data => {
						data.selected = true;
						return data;
					})
				}
			}
		case PROJECTS_UNSELECT_ALL:
			return {
				...state,
				projects: {
					...state.projects,
					records: state.projects.records.map(data => {
						data.selected = false;
						return data;
					})
				}
			}
		case PROJECT_SELECTED: 
			return {
				...state,
				selectedProject: action.payload,
				selectedProjectId: action.payload.id
			};
		case PROJECTS_LOAD: 
			return {
				...state,
				projectFilter: action.payload ? action.payload : state.projectFilter
			};
		case PROJECTS_LOAD_SUCCESS:
			return {
				...state,
				projects: action.payload
			};
		case PROJECTS_LOAD_FAILED:
			return {
				...state
            };
        case PROJECT_LOAD: 
			return {
				...state
			};
		case PROJECT_LOAD_SUCCESS:
			return {
				...state
			};
		case PROJECT_LOAD_FAILED:
			return {
				...state
			};
		case PROJECT_ADD:
			return {
				...state
			};
		case PROJECT_ADD_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PROJECT_ADD_FAILED:
			return {
				...state
			};
		case PROJECT_UPDATE:
			return {
				...state
			};
		case PROJECT_UPDATE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PROJECT_UPDATE_FAILED:
			return {
				...state
			};
		case PROJECT_DELETE:
			return {
				...state
			};
		case PROJECT_DELETE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PROJECT_DELETE_FAILED:
			return {
				...state
			};
		case PROJECT_DELETE_ALL:
			return {
				...state
			};
		case PROJECT_DELETE_ALL_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PROJECT_DELETE_ALL_FAILED:
			return {
				...state
			};
		default:
			return state;
	}
}