import {
	PRODUCTS_SELECT_ALL,
	PRODUCTS_UNSELECT_ALL,
	PRODUCT_SELECTED,
	PRODUCTS_LOAD,
	PRODUCTS_LOAD_SUCCESS,
    PRODUCTS_LOAD_FAILED,
    PRODUCT_LOAD,
	PRODUCT_LOAD_SUCCESS,
	PRODUCT_LOAD_FAILED,
	PRODUCT_ADD,
	PRODUCT_ADD_SUCCESS,
	PRODUCT_ADD_FAILED,
	PRODUCT_UPDATE,
	PRODUCT_UPDATE_SUCCESS,
	PRODUCT_UPDATE_FAILED,
	PRODUCT_DELETE,
	PRODUCT_DELETE_SUCCESS,
	PRODUCT_DELETE_FAILED,
	PRODUCT_DELETE_ALL,
	PRODUCT_DELETE_ALL_SUCCESS,
	PRODUCT_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

export const selectAllProducts = () => {
	return {
		type: PRODUCTS_SELECT_ALL
	}
}

export const unselectAllProducts = () => {
	return {
		type: PRODUCTS_UNSELECT_ALL
	}
}

export const selectProduct = data => {
	return {
		type: PRODUCT_SELECTED,
		payload: data
	}
}

export const loadProducts = (data) => {
	return {
		type: PRODUCTS_LOAD,
		payload: data
	};
}


export const loadProductsSuccess = (data) => {
	return {
        type: PRODUCTS_LOAD_SUCCESS,
        payload: data
	};
}


export const loadProductsFailed = () => {
	return {
		type: PRODUCTS_LOAD_FAILED
	};
}


export const loadProduct = () => {
	return {
		type: PRODUCT_LOAD
	};
}


export const loadProductSuccess = (data) => {
	return {
		type: PRODUCT_LOAD_SUCCESS,
		payload: data
	};
}


export const loadProductFailed = () => {
	return {
		type: PRODUCT_LOAD_FAILED
	};
}


export const addProduct = (data) => {
	return {
		type: PRODUCT_ADD,
		payload: data
	};
}


export const addProductSuccess = (filter) => {
	return {
		type: PRODUCT_ADD_SUCCESS,
		message: 'Product added successfully.',
		payload: filter
	};
}


export const addProductFailed = () => {
	return {
		type: PRODUCT_ADD_FAILED
	};
}


export const updateProduct = (data) => {
	return {
		type: PRODUCT_UPDATE,
		payload: data
	};
}


export const updateProductSuccess = (data) => {
	return {
		type: PRODUCT_UPDATE_SUCCESS,
		payload: data
	};
}


export const updateProductFailed = (data) => {
	return {
		type: PRODUCT_UPDATE_FAILED,
		payload: data
	};
}


export const deleteProduct = (data) => {
	return {
		type: PRODUCT_DELETE,
		payload: data
	};
}


export const deleteProductSuccess = () => {
	return {
		type: PRODUCT_DELETE_SUCCESS,
		message: 'Product deleted successfully.'
	};
}


export const deleteProductFailed = () => {
	return {
		type: PRODUCT_DELETE_FAILED
	};
}

export const deleteAllProducts = (data) => {
	return {
		type: PRODUCT_DELETE_ALL,
		payload: data
	};
}


export const deleteAllProductsSuccess = () => {
	return {
		type: PRODUCT_DELETE_ALL_SUCCESS,
		message: 'Product all deleted successfully.'
	};
}


export const deleteAllProductsFailed = () => {
	return {
		type: PRODUCT_DELETE_ALL_FAILED
	};
}

