import {
	PDFS_SELECT_ALL,
	PDFS_UNSELECT_ALL,
	PDF_SELECTED,
	PDFS_LOAD,
	PDFS_LOAD_SUCCESS,
    PDFS_LOAD_FAILED,
    PDF_LOAD,
	PDF_LOAD_SUCCESS,
	PDF_LOAD_FAILED,
	PDF_ADD,
	PDF_ADD_SUCCESS,
	PDF_ADD_FAILED,
	PDF_UPDATE,
	PDF_UPDATE_SUCCESS,
	PDF_UPDATE_FAILED,
	PDF_DELETE,
	PDF_DELETE_SUCCESS,
	PDF_DELETE_FAILED,
	PDF_DELETE_ALL,
	PDF_DELETE_ALL_SUCCESS,
	PDF_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
    pdfs: {
        records: [],
        offset: 0,
		limit: 50,
		totalRecords: 0
    },
    selectedPdf: null,
    selectedPdfId: null,
	message: null,
	messageType: 'info',
	pdfFilter: {
		offset: 0,
		limit: 1000,
		orderBy: 'name',
		filterGroups: null
	  }
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case PDFS_SELECT_ALL:
			return {
				...state,
				pdfs: {
					...state.pdfs,
					records: state.pdfs.records.map(data => {
						data.selected = true;
						return data;
					})
				}
			}
		case PDFS_UNSELECT_ALL:
			return {
				...state,
				pdfs: {
					...state.pdfs,
					records: state.pdfs.records.map(data => {
						data.selected = false;
						return data;
					})
				}
			}
		case PDF_SELECTED: 
			return {
				...state,
				selectedPdf: action.payload,
				selectedPdfId: action.payload.id
			};
		case PDFS_LOAD: 
			return {
				...state,
				pdfFilter: action.payload ? action.payload : state.pdfFilter
			};
		case PDFS_LOAD_SUCCESS:
			return {
				...state,
				pdfs: action.payload
			};
		case PDFS_LOAD_FAILED:
			return {
				...state
            };
        case PDF_LOAD: 
			return {
				...state
			};
		case PDF_LOAD_SUCCESS:
			return {
				...state
			};
		case PDF_LOAD_FAILED:
			return {
				...state
			};
		case PDF_ADD:
			return {
				...state
			};
		case PDF_ADD_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PDF_ADD_FAILED:
			return {
				...state
			};
		case PDF_UPDATE:
			return {
				...state
			};
		case PDF_UPDATE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PDF_UPDATE_FAILED:
			return {
				...state
			};
		case PDF_DELETE:
			return {
				...state
			};
		case PDF_DELETE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PDF_DELETE_FAILED:
			return {
				...state
			};
		case PDF_DELETE_ALL:
			return {
				...state
			};
		case PDF_DELETE_ALL_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case PDF_DELETE_ALL_FAILED:
			return {
				...state
			};
		default:
			return state;
	}
}