import firebase from "firebase";

var config = {
  apiKey: "AIzaSyCd4A7ia_DkSGMMwk7Hji4q2a5rEA9nW9k",
  authDomain: "wispec-2019.firebaseapp.com",
  databaseURL: "https://wispec-2019.firebaseio.com",
  projectId: "wispec-2019",
  storageBucket: "wispec-2019.appspot.com",
  messagingSenderId: "435916656859"
};

firebase.initializeApp(config);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
