import {
	INTRODUCTIONS_SELECT_ALL,
	INTRODUCTIONS_UNSELECT_ALL,
	INTRODUCTION_SELECTED,
	INTRODUCTIONS_LOAD,
	INTRODUCTIONS_LOAD_SUCCESS,
    INTRODUCTIONS_LOAD_FAILED,
    INTRODUCTION_LOAD,
	INTRODUCTION_LOAD_SUCCESS,
	INTRODUCTION_LOAD_FAILED,
	INTRODUCTION_ADD,
	INTRODUCTION_ADD_SUCCESS,
	INTRODUCTION_ADD_FAILED,
	INTRODUCTION_UPDATE,
	INTRODUCTION_UPDATE_SUCCESS,
	INTRODUCTION_UPDATE_FAILED,
	INTRODUCTION_DELETE,
	INTRODUCTION_DELETE_SUCCESS,
	INTRODUCTION_DELETE_FAILED,
	INTRODUCTION_DELETE_ALL,
	INTRODUCTION_DELETE_ALL_SUCCESS,
	INTRODUCTION_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

export const selectAllIntroductions = () => {
	return {
		type: INTRODUCTIONS_SELECT_ALL
	}
}

export const unselectAllIntroductions = () => {
	return {
		type: INTRODUCTIONS_UNSELECT_ALL
	}
}

export const selectIntroduction = data => {
	return {
		type: INTRODUCTION_SELECTED,
		payload: data
	}
}

export const loadIntroductions = (data) => {
	return {
		type: INTRODUCTIONS_LOAD,
		payload: data
	};
}


export const loadIntroductionsSuccess = (data) => {
	return {
        type: INTRODUCTIONS_LOAD_SUCCESS,
        payload: data
	};
}


export const loadIntroductionsFailed = () => {
	return {
		type: INTRODUCTIONS_LOAD_FAILED
	};
}


export const loadIntroduction = () => {
	return {
		type: INTRODUCTION_LOAD
	};
}


export const loadIntroductionSuccess = (data) => {
	return {
		type: INTRODUCTION_LOAD_SUCCESS,
		payload: data
	};
}


export const loadIntroductionFailed = () => {
	return {
		type: INTRODUCTION_LOAD_FAILED
	};
}


export const addIntroduction = (data) => {
	return {
		type: INTRODUCTION_ADD,
		payload: data
	};
}


export const addIntroductionSuccess = (filter) => {
	return {
		type: INTRODUCTION_ADD_SUCCESS,
		message: 'Introduction added successfully.',
		payload: filter
	};
}


export const addIntroductionFailed = () => {
	return {
		type: INTRODUCTION_ADD_FAILED
	};
}


export const updateIntroduction = (data) => {
	return {
		type: INTRODUCTION_UPDATE,
		payload: data
	};
}


export const updateIntroductionSuccess = (data) => {
	return {
		type: INTRODUCTION_UPDATE_SUCCESS,
		payload: data
	};
}


export const updateIntroductionFailed = (data) => {
	return {
		type: INTRODUCTION_UPDATE_FAILED,
		payload: data
	};
}


export const deleteIntroduction = (data) => {
	return {
		type: INTRODUCTION_DELETE,
		payload: data
	};
}


export const deleteIntroductionSuccess = () => {
	return {
		type: INTRODUCTION_DELETE_SUCCESS,
		message: 'Introduction deleted successfully.'
	};
}


export const deleteIntroductionFailed = () => {
	return {
		type: INTRODUCTION_DELETE_FAILED
	};
}

export const deleteAllIntroductions = (data) => {
	return {
		type: INTRODUCTION_DELETE_ALL,
		payload: data
	};
}


export const deleteAllIntroductionsSuccess = () => {
	return {
		type: INTRODUCTION_DELETE_ALL_SUCCESS,
		message: 'Introduction all deleted successfully.'
	};
}


export const deleteAllIntroductionsFailed = () => {
	return {
		type: INTRODUCTION_DELETE_ALL_FAILED
	};
}