import {
	BRIEFARTICLES_SELECT_ALL,
	BRIEFARTICLES_UNSELECT_ALL,
	BRIEFARTICLE_SELECTED,
	BRIEFARTICLES_LOAD,
	BRIEFARTICLES_LOAD_SUCCESS,
    BRIEFARTICLES_LOAD_FAILED,
    BRIEFARTICLE_LOAD,
	BRIEFARTICLE_LOAD_SUCCESS,
	BRIEFARTICLE_LOAD_FAILED,
	BRIEFARTICLE_ADD,
	BRIEFARTICLE_ADD_SUCCESS,
	BRIEFARTICLE_ADD_FAILED,
	BRIEFARTICLE_UPDATE,
	BRIEFARTICLE_UPDATE_SUCCESS,
	BRIEFARTICLE_UPDATE_FAILED,
	BRIEFARTICLE_DELETE,
	BRIEFARTICLE_DELETE_SUCCESS,
	BRIEFARTICLE_DELETE_FAILED,
	BRIEFARTICLE_DELETE_ALL,
	BRIEFARTICLE_DELETE_ALL_SUCCESS,
	BRIEFARTICLE_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
    briefArticles: {
        records: [],
        offset: 0,
		limit: 1000,
		totalRecords: 0
    },
    selectedBriefArticle: null,
    selectedBriefArticleId: null,
	selectedImage: null,
	selectedImageId: null,
	message: null,
	messageType: 'info',
	briefArticleFilter: {
		offset: 0,
		limit: 1000,
		orderBy: 'name',
		filterGroups: null
	  }
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case BRIEFARTICLES_SELECT_ALL:
			return {
				...state,
				briefArticles: {
					...state.briefArticles,
					records: state.briefArticles.records.map(data => {
						data.selected = true;
						return data;
					})
				}
			}
		case BRIEFARTICLES_UNSELECT_ALL:
			return {
				...state,
				briefArticles: {
					...state.briefArticles,
					records: state.briefArticles.records.map(data => {
						data.selected = false;
						return data;
					})
				}
			}
		case BRIEFARTICLE_SELECTED: 
			return {
				...state,
				selectedBriefArticle: action.payload,
				selectedBriefArticleId: action.payload.id
			};
		case BRIEFARTICLES_LOAD: 
			return {
				...state,
				briefArticleFilter: action.payload ? action.payload : state.briefArticleFilter
			};
		case BRIEFARTICLES_LOAD_SUCCESS:
			return {
				...state,
				briefArticles: action.payload
			};
		case BRIEFARTICLES_LOAD_FAILED:
			return {
				...state
            };
        case BRIEFARTICLE_LOAD: 
			return {
				...state
			};
		case BRIEFARTICLE_LOAD_SUCCESS:
			return {
				...state
			};
		case BRIEFARTICLE_LOAD_FAILED:
			return {
				...state
			};
		case BRIEFARTICLE_ADD:
			return {
				...state
			};
		case BRIEFARTICLE_ADD_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case BRIEFARTICLE_ADD_FAILED:
			return {
				...state
			};
		case BRIEFARTICLE_UPDATE:
			return {
				...state
			};
		case BRIEFARTICLE_UPDATE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case BRIEFARTICLE_UPDATE_FAILED:
			return {
				...state
			};
		case BRIEFARTICLE_DELETE:
			return {
				...state
			};
		case BRIEFARTICLE_DELETE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case BRIEFARTICLE_DELETE_FAILED:
			return {
				...state
			};
		case BRIEFARTICLE_DELETE_ALL:
			return {
				...state
			};
		case BRIEFARTICLE_DELETE_ALL_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case BRIEFARTICLE_DELETE_ALL_FAILED:
			return {
				...state
			};
		default:
			return state;
	}
}