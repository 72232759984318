import {
	INTRODUCTIONS_SELECT_ALL,
	INTRODUCTIONS_UNSELECT_ALL,
	INTRODUCTION_SELECTED,
	INTRODUCTIONS_LOAD,
	INTRODUCTIONS_LOAD_SUCCESS,
    INTRODUCTIONS_LOAD_FAILED,
    INTRODUCTION_LOAD,
	INTRODUCTION_LOAD_SUCCESS,
	INTRODUCTION_LOAD_FAILED,
	INTRODUCTION_ADD,
	INTRODUCTION_ADD_SUCCESS,
	INTRODUCTION_ADD_FAILED,
	INTRODUCTION_UPDATE,
	INTRODUCTION_UPDATE_SUCCESS,
	INTRODUCTION_UPDATE_FAILED,
	INTRODUCTION_DELETE,
	INTRODUCTION_DELETE_SUCCESS,
	INTRODUCTION_DELETE_FAILED,
	INTRODUCTION_DELETE_ALL,
	INTRODUCTION_DELETE_ALL_SUCCESS,
	INTRODUCTION_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

const INIT_STATE = {
    introductions: {
        records: [],
        offset: 0,
		limit: 1000,
		totalRecords: 0
    },
    selectedIntroduction: null,
    selectedIntroductionId: null,
  	selectedImage: null,
	selectedImageId: null,
	message: null,
	messageType: 'info',
	introductionFilter: {
		offset: 0,
		limit: 1000,
		orderBy: 'name',
		filterGroups: null
	  }
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case INTRODUCTIONS_SELECT_ALL:
			return {
				...state,
				introductions: {
					...state.introductions,
					records: state.introductions.records.map(data => {
						data.selected = true;
						return data;
					})
				}
			}
		case INTRODUCTIONS_UNSELECT_ALL:
			return {
				...state,
				introductions: {
					...state.introductions,
					records: state.introductions.records.map(data => {
						data.selected = false;
						return data;
					})
				}
			}
		case INTRODUCTION_SELECTED: 
			return {
				...state,
				selectedIntroduction: action.payload,
				selectedIntroductionId: action.payload.id
			};
		case INTRODUCTIONS_LOAD: 
			return {
				...state,
				introductionFilter: action.payload ? action.payload : state.introductionFilter
			};
		case INTRODUCTIONS_LOAD_SUCCESS:
			return {
				...state,
				introductions: action.payload
			};
		case INTRODUCTIONS_LOAD_FAILED:
			return {
				...state
            };
        case INTRODUCTION_LOAD: 
			return {
				...state
			};
		case INTRODUCTION_LOAD_SUCCESS:
			return {
				...state
			};
		case INTRODUCTION_LOAD_FAILED:
			return {
				...state
			};
		case INTRODUCTION_ADD:
			return {
				...state
			};
		case INTRODUCTION_ADD_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case INTRODUCTION_ADD_FAILED:
			return {
				...state
			};
		case INTRODUCTION_UPDATE:
			return {
				...state
			};
		case INTRODUCTION_UPDATE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case INTRODUCTION_UPDATE_FAILED:
			return {
				...state
			};
		case INTRODUCTION_DELETE:
			return {
				...state
			};
		case INTRODUCTION_DELETE_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case INTRODUCTION_DELETE_FAILED:
			return {
				...state
			};
		case INTRODUCTION_DELETE_ALL:
			return {
				...state
			};
		case INTRODUCTION_DELETE_ALL_SUCCESS:
			return {
				...state,
				message: action.message
			};
		case INTRODUCTION_DELETE_ALL_FAILED:
			return {
				...state
			};
		default:
			return state;
	}
}
