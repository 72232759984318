import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadContacts as loadContactsAction,
	loadContactSuccess,
	loadContactsSuccess,
	addContactSuccess,
	updateContactSuccess,
    deleteContactSuccess,
    deleteAllContactsSuccess
} from '../actions/Contact';
import {
	loadContactsFailed,
	loadContactFailed,
	addContactFailed,
	updateContactFailed,
	deleteContactFailed
} from '../actions/Contact';


import {
	CONTACTS_LOAD,
	CONTACT_LOAD,
	CONTACT_ADD,
	CONTACT_UPDATE,
    CONTACT_DELETE,
    CONTACT_DELETE_ALL,
    CONTACT_DELETE_ALL_SUCCESS,
    CONTACT_DELETE_ALL_FAILED,
	IMAGE_LOAD,
	IMAGE_ADD,
	IMAGE_UPDATE,
	IMAGE_DELETE,
    CONTACT_ADD_SUCCESS,
    CONTACT_UPDATE_SUCCESS,
} from '../../constants/ActionTypes';

import {
    userSignOut
} from '../actions/Auth';

const loadContactsRequest = async (params) =>{
    if (params.filterGroups && params.filterGroups.length){
        return await Api().post(`contacts/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`contacts`, { params })
        .then(data => data)
        .catch(error => error);
}
    

const loadContactRequest = async (id) =>
    await Api().get(`contacts/${id}`)
        .then(data => data)
        .catch(error => error);

const addContactRequest = async (payload) =>
    await Api().post(`contacts`, payload)
      .then(data => data)
      .catch(error => error);

const updateContactRequest = async (payload) =>
    await Api().patch(`contacts/${payload.id}`, patchData(payload))
      .then(data => data)
      .catch(error => error);

const deleteContactRequest = async (id) =>
    await Api().delete(`contacts/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadContacts({payload}) {
    try {
        const data = yield call(loadContactsRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadContactsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshContacts({payload}) {
    yield loadContacts(payload);
}

function* processLoadContacts() {
    yield takeEvery(CONTACTS_LOAD, loadContacts);
}

function* loadContact({selectedContactId}) {
    try {
        const data = yield call(loadContactRequest, selectedContactId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadContactSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadContact() {
    yield takeEvery(CONTACT_LOAD, loadContact);
}


function* addContact({payload}) {
    try {
        const data = yield call(addContactRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadContactsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddContact() {
    yield takeEvery(CONTACT_ADD, addContact);
}


function* updateContact({payload}) {
    try {
        const data = yield call(updateContactRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadContactsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateContact() {
    yield takeEvery(CONTACT_UPDATE, updateContact);
}

function* deleteContact({payload}) {
    try {
        const data = yield call(deleteContactRequest, payload.model.id);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadContactsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllContacts({payload}) {
    try {        
        for(let i = 0; i < payload.models.length; ++i){
            let data = yield call(deleteContactRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }
        
        yield put(loadContactsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteContact() {
    yield takeEvery(CONTACT_DELETE, deleteContact);
}

function* processDeleteAllContacts() {
    yield takeEvery(CONTACT_DELETE_ALL, deleteAllContacts);
}

export default function* contactSagas() {
    yield all([fork(processLoadContacts),
      fork(processLoadContact),
      fork(processAddContact),
      fork(processUpdateContact),
      fork(processDeleteAllContacts),
      fork(processDeleteContact)]);
}