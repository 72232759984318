import {
	CONTACTS_SELECT_ALL,
	CONTACTS_UNSELECT_ALL,
	CONTACT_SELECTED,
	CONTACTS_LOAD,
	CONTACTS_LOAD_SUCCESS,
    CONTACTS_LOAD_FAILED,
    CONTACT_LOAD,
	CONTACT_LOAD_SUCCESS,
	CONTACT_LOAD_FAILED,
	CONTACT_ADD,
	CONTACT_ADD_SUCCESS,
	CONTACT_ADD_FAILED,
	CONTACT_UPDATE,
	CONTACT_UPDATE_SUCCESS,
	CONTACT_UPDATE_FAILED,
	CONTACT_DELETE,
	CONTACT_DELETE_SUCCESS,
	CONTACT_DELETE_FAILED,
	CONTACT_DELETE_ALL,
	CONTACT_DELETE_ALL_SUCCESS,
	CONTACT_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

export const selectAllContacts = () => {
	return {
		type: CONTACTS_SELECT_ALL
	}
}

export const unselectAllContacts = () => {
	return {
		type: CONTACTS_UNSELECT_ALL
	}
}

export const selectContact = data => {
	return {
		type: CONTACT_SELECTED,
		payload: data
	}
}

export const loadContacts = (data) => {
	return {
		type: CONTACTS_LOAD,
		payload: data
	};
}


export const loadContactsSuccess = (data) => {
	return {
        type: CONTACTS_LOAD_SUCCESS,
        payload: data
	};
}


export const loadContactsFailed = () => {
	return {
		type: CONTACTS_LOAD_FAILED
	};
}


export const loadContact = () => {
	return {
		type: CONTACT_LOAD
	};
}


export const loadContactSuccess = (data) => {
	return {
		type: CONTACT_LOAD_SUCCESS,
		payload: data
	};
}


export const loadContactFailed = () => {
	return {
		type: CONTACT_LOAD_FAILED
	};
}


export const addContact = (data) => {
	return {
		type: CONTACT_ADD,
		payload: data
	};
}


export const addContactSuccess = (filter) => {
	return {
		type: CONTACT_ADD_SUCCESS,
		message: 'Contact added successfully.',
		payload: filter
	};
}


export const addContactFailed = () => {
	return {
		type: CONTACT_ADD_FAILED
	};
}


export const updateContact = (data) => {
	return {
		type: CONTACT_UPDATE,
		payload: data
	};
}


export const updateContactSuccess = (data) => {
	return {
		type: CONTACT_UPDATE_SUCCESS,
		payload: data
	};
}


export const updateContactFailed = (data) => {
	return {
		type: CONTACT_UPDATE_FAILED,
		payload: data
	};
}


export const deleteContact = (data) => {
	return {
		type: CONTACT_DELETE,
		payload: data
	};
}


export const deleteContactSuccess = () => {
	return {
		type: CONTACT_DELETE_SUCCESS,
		message: 'Contact deleted successfully.'
	};
}


export const deleteContactFailed = () => {
	return {
		type: CONTACT_DELETE_FAILED
	};
}

export const deleteAllContacts = (data) => {
	return {
		type: CONTACT_DELETE_ALL,
		payload: data
	};
}


export const deleteAllContactsSuccess = () => {
	return {
		type: CONTACT_DELETE_ALL_SUCCESS,
		message: 'Contact all deleted successfully.'
	};
}


export const deleteAllContactsFailed = () => {
	return {
		type: CONTACT_DELETE_ALL_FAILED
	};
}