import {
	PDFS_SELECT_ALL,
	PDFS_UNSELECT_ALL,
	PDF_SELECTED,
	PDFS_LOAD,
	PDFS_LOAD_SUCCESS,
    PDFS_LOAD_FAILED,
    PDF_LOAD,
	PDF_LOAD_SUCCESS,
	PDF_LOAD_FAILED,
	PDF_ADD,
	PDF_ADD_SUCCESS,
	PDF_ADD_FAILED,
	PDF_UPDATE,
	PDF_UPDATE_SUCCESS,
	PDF_UPDATE_FAILED,
	PDF_DELETE,
	PDF_DELETE_SUCCESS,
	PDF_DELETE_FAILED,
	PDF_DELETE_ALL,
	PDF_DELETE_ALL_SUCCESS,
	PDF_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

export const selectAllPdfs = () => {
	return {
		type: PDFS_SELECT_ALL
	}
}

export const unselectAllPdfs = () => {
	return {
		type: PDFS_UNSELECT_ALL
	}
}

export const selectPdf = data => {
	return {
		type: PDF_SELECTED,
		payload: data
	}
}

export const loadPdfs = (data) => {
	return {
		type: PDFS_LOAD,
		payload: data
	};
}


export const loadPdfsSuccess = (data) => {
	return {
        type: PDFS_LOAD_SUCCESS,
        payload: data
	};
}


export const loadPdfsFailed = () => {
	return {
		type: PDFS_LOAD_FAILED
	};
}


export const loadPdf = () => {
	return {
		type: PDF_LOAD
	};
}


export const loadPdfSuccess = (data) => {
	return {
		type: PDF_LOAD_SUCCESS,
		payload: data
	};
}


export const loadPdfFailed = () => {
	return {
		type: PDF_LOAD_FAILED
	};
}


export const addPdf = (data) => {
	return {
		type: PDF_ADD,
		payload: data
	};
}


export const addPdfSuccess = (filter) => {
	return {
		type: PDF_ADD_SUCCESS,
		message: 'Pdf added successfully.',
		payload: filter
	};
}


export const addPdfFailed = () => {
	return {
		type: PDF_ADD_FAILED
	};
}


export const updatePdf = (data) => {
	return {
		type: PDF_UPDATE,
		payload: data
	};
}


export const updatePdfSuccess = (data) => {
	return {
		type: PDF_UPDATE_SUCCESS,
		payload: data
	};
}


export const updatePdfFailed = (data) => {
	return {
		type: PDF_UPDATE_FAILED,
		payload: data
	};
}


export const deletePdf = (data) => {
	return {
		type: PDF_DELETE,
		payload: data
	};
}


export const deletePdfSuccess = () => {
	return {
		type: PDF_DELETE_SUCCESS,
		message: 'Pdf deleted successfully.'
	};
}


export const deletePdfFailed = () => {
	return {
		type: PDF_DELETE_FAILED
	};
}

export const deleteAllPdfs = (data) => {
	return {
		type: PDF_DELETE_ALL,
		payload: data
	};
}


export const deleteAllPdfsSuccess = () => {
	return {
		type: PDF_DELETE_ALL_SUCCESS,
		message: 'Pdf all deleted successfully.'
	};
}


export const deleteAllPdfsFailed = () => {
	return {
		type: PDF_DELETE_ALL_FAILED
	};
}

