import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import albumSagas from "./Album";
import projectSagas from "./Project";
import productSagas from "./Product";
import briefArticleSagas from "./BriefArticle";
import introductionSagas from "./Introduction";
import contactSagas from "./Contact";
import aboutUsSagas from "./AboutUs";
import imageSagas from "./Image";
import pdfSagas from "./Pdf";
import pdfAlbumSagas from "./PdfAlbum";
import statisticSagas from "./Statistic";
import productTypeSagas from './ProductType';
import serviceTypeSagas from './ServiceType';
import menuSagas from './Menu';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    albumSagas(),
    projectSagas(),
    productSagas(),
    briefArticleSagas(),
    introductionSagas(),
    contactSagas(),
    aboutUsSagas(),
    imageSagas(),
    pdfAlbumSagas(),
    pdfSagas(),
    statisticSagas(),
    productTypeSagas(),
    serviceTypeSagas(),
    menuSagas()
  ]);
}
