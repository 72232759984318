// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

export const ALBUMS_SELECT_ALL = 'ALBUMS_SELECT_ALL';
export const ALBUMS_UNSELECT_ALL = 'ALBUMS_UNSELECT_ALL';
export const ALBUM_SELECTED = 'ALBUM_SELECTED';
export const ALBUM_LOAD = 'ALBUM_LOAD';
export const ALBUM_LOAD_SUCCESS = 'ALBUM_LOAD_SUCCESS';
export const ALBUM_LOAD_FAILED = 'ALBUM_LOAD_FAILED';
export const ALBUMS_LOAD = 'ALBUMS_LOAD';
export const ALBUMS_LOAD_SUCCESS = 'ALBUMS_LOAD_SUCCESS';
export const ALBUMS_LOAD_FAILED = 'ALBUMS_LOAD_FAILED';
export const ALBUM_ADD = 'ALBUM_ADD';
export const ALBUM_ADD_SUCCESS = 'ALBUM_ADD_SUCCESS';
export const ALBUM_ADD_FAILED = 'ALBUM_ADD_FAILED';
export const ALBUM_UPDATE = 'ALBUM_UPDATE';
export const ALBUM_UPDATE_SUCCESS = 'ALBUM_UPDATE_SUCCESS';
export const ALBUM_UPDATE_FAILED = 'ALBUM_UPDATE_FAILED';
export const ALBUM_DELETE = 'ALBUM_DELETE';
export const ALBUM_DELETE_SUCCESS = 'ALBUM_DELETE_SUCCESS';
export const ALBUM_DELETE_FAILED = 'ALBUM_DELETE_FAILED';
export const ALBUM_DELETE_ALL = 'ALBUM_DELETE_ALL';
export const ALBUM_DELETE_ALL_SUCCESS = 'ALBUM_DELETE_ALL_SUCCESS';
export const ALBUM_DELETE_ALL_FAILED = 'ALBUM_DELETE_ALL_FAILED';


export const IMAGES_SELECT_ALL = 'IMAGES_SELECT_ALL';
export const IMAGES_UNSELECT_ALL = 'IMAGES_UNSELECT_ALL';
export const IMAGE_SELECTED = 'IMAGE_SELECTED';
export const IMAGE_LOAD = 'IMAGE_LOAD';
export const IMAGE_LOAD_SUCCESS = 'IMAGE_LOAD_SUCCESS';
export const IMAGE_LOAD_FAILED = 'IMAGE_LOAD_FAILED';
export const IMAGES_LOAD = 'IMAGES_LOAD';
export const IMAGES_LOAD_SUCCESS = 'IMAGES_LOAD_SUCCESS';
export const IMAGES_LOAD_FAILED = 'IMAGES_LOAD_FAILED';
export const IMAGE_ADD = 'IMAGE_ADD';
export const IMAGE_ADD_SUCCESS = 'IMAGE_ADD_SUCCESS';
export const IMAGE_ADD_FAILED = 'IMAGE_ADD_FAILED';
export const IMAGE_UPDATE = 'IMAGE_UPDATE';
export const IMAGE_UPDATE_SUCCESS = 'IMAGE_UPDATE_SUCCESS';
export const IMAGE_UPDATE_FAILED = 'IMAGE_UPDATE_FAILED';
export const IMAGE_DELETE = 'IMAGE_DELETE';
export const IMAGE_DELETE_SUCCESS = 'IMAGE_DELETE_SUCCESS';
export const IMAGE_DELETE_FAILED = 'IMAGE_DELETE_FAILED';
export const IMAGE_DELETE_ALL = 'IMAGE_DELETE_ALL';
export const IMAGE_DELETE_ALL_SUCCESS = 'IMAGE_DELETE_ALL_SUCCESS';
export const IMAGE_DELETE_ALL_FAILED = 'IMAGE_DELETE_ALL_FAILED';


export const PDFS_SELECT_ALL = 'PDFS_SELECT_ALL';
export const PDFS_UNSELECT_ALL = 'PDFS_UNSELECT_ALL';
export const PDF_SELECTED = 'PDF_SELECTED';
export const PDF_LOAD = 'PDF_LOAD';
export const PDF_LOAD_SUCCESS = 'PDF_LOAD_SUCCESS';
export const PDF_LOAD_FAILED = 'PDF_LOAD_FAILED';
export const PDFS_LOAD = 'PDFS_LOAD';
export const PDFS_LOAD_SUCCESS = 'PDFS_LOAD_SUCCESS';
export const PDFS_LOAD_FAILED = 'PDFS_LOAD_FAILED';
export const PDF_ADD = 'PDF_ADD';
export const PDF_ADD_SUCCESS = 'PDF_ADD_SUCCESS';
export const PDF_ADD_FAILED = 'PDF_ADD_FAILED';
export const PDF_UPDATE = 'PDF_UPDATE';
export const PDF_UPDATE_SUCCESS = 'PDF_UPDATE_SUCCESS';
export const PDF_UPDATE_FAILED = 'PDF_UPDATE_FAILED';
export const PDF_DELETE = 'PDF_DELETE';
export const PDF_DELETE_SUCCESS = 'PDF_DELETE_SUCCESS';
export const PDF_DELETE_FAILED = 'PDF_DELETE_FAILED';
export const PDF_DELETE_ALL = 'PDF_DELETE_ALL';
export const PDF_DELETE_ALL_SUCCESS = 'PDF_DELETE_ALL_SUCCESS';
export const PDF_DELETE_ALL_FAILED = 'PDF_DELETE_ALL_FAILED';


export const PDFALBUMS_SELECT_ALL = 'PDFALBUMS_SELECT_ALL';
export const PDFALBUMS_UNSELECT_ALL = 'PDFALBUMS_UNSELECT_ALL';
export const PDFALBUM_SELECTED = 'PDFALBUM_SELECTED';
export const PDFALBUM_LOAD = 'PDFALBUM_LOAD';
export const PDFALBUM_LOAD_SUCCESS = 'PDFALBUM_LOAD_SUCCESS';
export const PDFALBUM_LOAD_FAILED = 'PDFALBUM_LOAD_FAILED';
export const PDFALBUMS_LOAD = 'PDFALBUMS_LOAD';
export const PDFALBUMS_LOAD_SUCCESS = 'PDFALBUMS_LOAD_SUCCESS';
export const PDFALBUMS_LOAD_FAILED = 'PDFALBUMS_LOAD_FAILED';
export const PDFALBUM_ADD = 'PDFALBUM_ADD';
export const PDFALBUM_ADD_SUCCESS = 'PDFALBUM_ADD_SUCCESS';
export const PDFALBUM_ADD_FAILED = 'PDFALBUM_ADD_FAILED';
export const PDFALBUM_UPDATE = 'PDFALBUM_UPDATE';
export const PDFALBUM_UPDATE_SUCCESS = 'PDFALBUM_UPDATE_SUCCESS';
export const PDFALBUM_UPDATE_FAILED = 'PDFALBUM_UPDATE_FAILED';
export const PDFALBUM_DELETE = 'PDFALBUM_DELETE';
export const PDFALBUM_DELETE_SUCCESS = 'PDFALBUM_DELETE_SUCCESS';
export const PDFALBUM_DELETE_FAILED = 'PDFALBUM_DELETE_FAILED';
export const PDFALBUM_DELETE_ALL = 'PDFALBUM_DELETE_ALL';
export const PDFALBUM_DELETE_ALL_SUCCESS = 'PDFALBUM_DELETE_ALL_SUCCESS';
export const PDFALBUM_DELETE_ALL_FAILED = 'PDFALBUM_DELETE_ALL_FAILED';


export const PROJECTS_SELECT_ALL = 'PROJECTS_SELECT_ALL';
export const PROJECTS_UNSELECT_ALL = 'PROJECTS_UNSELECT_ALL';
export const PROJECT_SELECTED = 'PROJECT_SELECTED';
export const PROJECT_LOAD = 'PROJECT_LOAD';
export const PROJECT_LOAD_SUCCESS = 'PROJECT_LOAD_SUCCESS';
export const PROJECT_LOAD_FAILED = 'PROJECT_LOAD_FAILED';
export const PROJECTS_LOAD = 'PROJECTS_LOAD';
export const PROJECTS_LOAD_SUCCESS = 'PROJECTS_LOAD_SUCCESS';
export const PROJECTS_LOAD_FAILED = 'PROJECTS_LOAD_FAILED';
export const PROJECT_ADD = 'PROJECT_ADD';
export const PROJECT_ADD_SUCCESS = 'PROJECT_ADD_SUCCESS';
export const PROJECT_ADD_FAILED = 'PROJECT_ADD_FAILED';
export const PROJECT_UPDATE = 'PROJECT_UPDATE';
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS';
export const PROJECT_UPDATE_FAILED = 'PROJECT_UPDATE_FAILED';
export const PROJECT_DELETE = 'PROJECT_DELETE';
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS';
export const PROJECT_DELETE_FAILED = 'PROJECT_DELETE_FAILED';
export const PROJECT_DELETE_ALL = 'PROJECT_DELETE_ALL';
export const PROJECT_DELETE_ALL_SUCCESS = 'PROJECT_DELETE_ALL_SUCCESS';
export const PROJECT_DELETE_ALL_FAILED = 'PROJECT_DELETE_ALL_FAILED';

// Product
export const PRODUCTS_SELECT_ALL = 'PRODUCTS_SELECT_ALL';
export const PRODUCTS_UNSELECT_ALL = 'PRODUCTS_UNSELECT_ALL';
export const PRODUCT_SELECTED = 'PRODUCT_SELECTED';
export const PRODUCT_LOAD = 'PRODUCT_LOAD';
export const PRODUCT_LOAD_SUCCESS = 'PRODUCT_LOAD_SUCCESS';
export const PRODUCT_LOAD_FAILED = 'PRODUCT_LOAD_FAILED';
export const PRODUCTS_LOAD = 'PRODUCTS_LOAD';
export const PRODUCTS_LOAD_SUCCESS = 'PRODUCTS_LOAD_SUCCESS';
export const PRODUCTS_LOAD_FAILED = 'PRODUCTS_LOAD_FAILED';
export const PRODUCT_ADD = 'PRODUCT_ADD';
export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS';
export const PRODUCT_ADD_FAILED = 'PRODUCT_ADD_FAILED';
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAILED = 'PRODUCT_UPDATE_FAILED';
export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAILED = 'PRODUCT_DELETE_FAILED';
export const PRODUCT_DELETE_ALL = 'PRODUCT_DELETE_ALL';
export const PRODUCT_DELETE_ALL_SUCCESS = 'PRODUCT_DELETE_ALL_SUCCESS';
export const PRODUCT_DELETE_ALL_FAILED = 'PRODUCT_DELETE_ALL_FAILED';

// Product type
export const PRODUCT_TYPES_SELECT_ALL = 'PRODUCT_TYPES_SELECT_ALL';
export const PRODUCT_TYPES_UNSELECT_ALL = 'PRODUCT_TYPES_UNSELECT_ALL';
export const PRODUCT_TYPE_SELECTED = 'PRODUCT_TYPE_SELECTED';
export const PRODUCT_TYPE_LOAD = 'PRODUCT_TYPE_LOAD';
export const PRODUCT_TYPE_LOAD_SUCCESS = 'PRODUCT_TYPE_LOAD_SUCCESS';
export const PRODUCT_TYPE_LOAD_FAILED = 'PRODUCT_TYPE_LOAD_FAILED';
export const PRODUCT_TYPES_LOAD = 'PRODUCT_TYPES_LOAD';
export const PRODUCT_TYPES_LOAD_SUCCESS = 'PRODUCT_TYPES_LOAD_SUCCESS';
export const PRODUCT_TYPES_LOAD_FAILED = 'PRODUCT_TYPES_LOAD_FAILED';
export const PRODUCT_TYPE_ADD = 'PRODUCT_TYPE_ADD';
export const PRODUCT_TYPE_ADD_SUCCESS = 'PRODUCT_TYPE_ADD_SUCCESS';
export const PRODUCT_TYPE_ADD_FAILED = 'PRODUCT_TYPE_ADD_FAILED';
export const PRODUCT_TYPE_UPDATE = 'PRODUCT_TYPE_UPDATE';
export const PRODUCT_TYPE_UPDATE_SUCCESS = 'PRODUCT_TYPE_UPDATE_SUCCESS';
export const PRODUCT_TYPE_UPDATE_FAILED = 'PRODUCT_TYPE_UPDATE_FAILED';
export const PRODUCT_TYPE_DELETE = 'PRODUCT_TYPE_DELETE';
export const PRODUCT_TYPE_DELETE_SUCCESS = 'PRODUCT_TYPE_DELETE_SUCCESS';
export const PRODUCT_TYPE_DELETE_FAILED = 'PRODUCT_TYPE_DELETE_FAILED';
export const PRODUCT_TYPE_DELETE_ALL = 'PRODUCT_TYPE_DELETE_ALL';
export const PRODUCT_TYPE_DELETE_ALL_SUCCESS = 'PRODUCT_TYPE_DELETE_ALL_SUCCESS';
export const PRODUCT_TYPE_DELETE_ALL_FAILED = 'PRODUCT_TYPE_DELETE_ALL_FAILED';
export const PRODUCT_TYPE_RESTORE = 'PRODUCT_TYPE_RESTORE';
export const PRODUCT_TYPE_RESTORE_SUCCESS = 'PRODUCT_TYPE_RESTORE_SUCCESS';
export const PRODUCT_TYPE_RESTORE_FAILED = 'PRODUCT_TYPE_RESTORE_FAILED';

// Service
export const BRIEFARTICLES_SELECT_ALL = 'BRIEFARTICLES_SELECT_ALL';
export const BRIEFARTICLES_UNSELECT_ALL = 'BRIEFARTICLES_UNSELECT_ALL';
export const BRIEFARTICLE_SELECTED = 'BRIEFARTICLE_SELECTED';
export const BRIEFARTICLE_LOAD = 'BRIEFARTICLE_LOAD';
export const BRIEFARTICLE_LOAD_SUCCESS = 'BRIEFARTICLE_LOAD_SUCCESS';
export const BRIEFARTICLE_LOAD_FAILED = 'BRIEFARTICLE_LOAD_FAILED';
export const BRIEFARTICLES_LOAD = 'BRIEFARTICLES_LOAD';
export const BRIEFARTICLES_LOAD_SUCCESS = 'BRIEFARTICLES_LOAD_SUCCESS';
export const BRIEFARTICLES_LOAD_FAILED = 'BRIEFARTICLES_LOAD_FAILED';
export const BRIEFARTICLE_ADD = 'BRIEFARTICLE_ADD';
export const BRIEFARTICLE_ADD_SUCCESS = 'BRIEFARTICLE_ADD_SUCCESS';
export const BRIEFARTICLE_ADD_FAILED = 'BRIEFARTICLE_ADD_FAILED';
export const BRIEFARTICLE_UPDATE = 'BRIEFARTICLE_UPDATE';
export const BRIEFARTICLE_UPDATE_SUCCESS = 'BRIEFARTICLE_UPDATE_SUCCESS';
export const BRIEFARTICLE_UPDATE_FAILED = 'BRIEFARTICLE_UPDATE_FAILED';
export const BRIEFARTICLE_DELETE = 'BRIEFARTICLE_DELETE';
export const BRIEFARTICLE_DELETE_SUCCESS = 'BRIEFARTICLE_DELETE_SUCCESS';
export const BRIEFARTICLE_DELETE_FAILED = 'BRIEFARTICLE_DELETE_FAILED';
export const BRIEFARTICLE_DELETE_ALL = 'BRIEFARTICLE_DELETE_ALL';
export const BRIEFARTICLE_DELETE_ALL_SUCCESS = 'BRIEFARTICLE_DELETE_ALL_SUCCESS';
export const BRIEFARTICLE_DELETE_ALL_FAILED = 'BRIEFARTICLE_DELETE_ALL_FAILED';

// Service type
export const SERVICE_TYPES_SELECT_ALL = 'SERVICE_TYPES_SELECT_ALL';
export const SERVICE_TYPES_UNSELECT_ALL = 'SERVICE_TYPES_UNSELECT_ALL';
export const SERVICE_TYPE_SELECTED = 'SERVICE_TYPE_SELECTED';
export const SERVICE_TYPE_LOAD = 'SERVICE_TYPE_LOAD';
export const SERVICE_TYPE_LOAD_SUCCESS = 'SERVICE_TYPE_LOAD_SUCCESS';
export const SERVICE_TYPE_LOAD_FAILED = 'SERVICE_TYPE_LOAD_FAILED';
export const SERVICE_TYPES_LOAD = 'SERVICE_TYPES_LOAD';
export const SERVICE_TYPES_LOAD_SUCCESS = 'SERVICE_TYPES_LOAD_SUCCESS';
export const SERVICE_TYPES_LOAD_FAILED = 'SERVICE_TYPES_LOAD_FAILED';
export const SERVICE_TYPE_ADD = 'SERVICE_TYPE_ADD';
export const SERVICE_TYPE_ADD_SUCCESS = 'SERVICE_TYPE_ADD_SUCCESS';
export const SERVICE_TYPE_ADD_FAILED = 'SERVICE_TYPE_ADD_FAILED';
export const SERVICE_TYPE_UPDATE = 'SERVICE_TYPE_UPDATE';
export const SERVICE_TYPE_UPDATE_SUCCESS = 'SERVICE_TYPE_UPDATE_SUCCESS';
export const SERVICE_TYPE_UPDATE_FAILED = 'SERVICE_TYPE_UPDATE_FAILED';
export const SERVICE_TYPE_DELETE = 'SERVICE_TYPE_DELETE';
export const SERVICE_TYPE_DELETE_SUCCESS = 'SERVICE_TYPE_DELETE_SUCCESS';
export const SERVICE_TYPE_DELETE_FAILED = 'SERVICE_TYPE_DELETE_FAILED';
export const SERVICE_TYPE_DELETE_ALL = 'SERVICE_TYPE_DELETE_ALL';
export const SERVICE_TYPE_DELETE_ALL_SUCCESS = 'SERVICE_TYPE_DELETE_ALL_SUCCESS';
export const SERVICE_TYPE_DELETE_ALL_FAILED = 'SERVICE_TYPE_DELETE_ALL_FAILED';
export const SERVICE_TYPE_RESTORE = 'SERVICE_TYPE_RESTORE';
export const SERVICE_TYPE_RESTORE_SUCCESS = 'SERVICE_TYPE_RESTORE_SUCCESS';
export const SERVICE_TYPE_RESTORE_FAILED = 'SERVICE_TYPE_RESTORE_FAILED';

// Introduction
export const INTRODUCTIONS_SELECT_ALL = 'INTRODUCTIONS_SELECT_ALL';
export const INTRODUCTIONS_UNSELECT_ALL = 'INTRODUCTIONS_UNSELECT_ALL';
export const INTRODUCTION_SELECTED = 'INTRODUCTION_SELECTED';
export const INTRODUCTION_LOAD = 'INTRODUCTION_LOAD';
export const INTRODUCTION_LOAD_SUCCESS = 'INTRODUCTION_LOAD_SUCCESS';
export const INTRODUCTION_LOAD_FAILED = 'INTRODUCTION_LOAD_FAILED';
export const INTRODUCTIONS_LOAD = 'INTRODUCTIONS_LOAD';
export const INTRODUCTIONS_LOAD_SUCCESS = 'INTRODUCTIONS_LOAD_SUCCESS';
export const INTRODUCTIONS_LOAD_FAILED = 'INTRODUCTIONS_LOAD_FAILED';
export const INTRODUCTION_ADD = 'INTRODUCTION_ADD';
export const INTRODUCTION_ADD_SUCCESS = 'INTRODUCTION_ADD_SUCCESS';
export const INTRODUCTION_ADD_FAILED = 'INTRODUCTION_ADD_FAILED';
export const INTRODUCTION_UPDATE = 'INTRODUCTION_UPDATE';
export const INTRODUCTION_UPDATE_SUCCESS = 'INTRODUCTION_UPDATE_SUCCESS';
export const INTRODUCTION_UPDATE_FAILED = 'INTRODUCTION_UPDATE_FAILED';
export const INTRODUCTION_DELETE = 'INTRODUCTION_DELETE';
export const INTRODUCTION_DELETE_SUCCESS = 'INTRODUCTION_DELETE_SUCCESS';
export const INTRODUCTION_DELETE_FAILED = 'INTRODUCTION_DELETE_FAILED';
export const INTRODUCTION_DELETE_ALL = 'INTRODUCTION_DELETE_ALL';
export const INTRODUCTION_DELETE_ALL_SUCCESS = 'INTRODUCTION_DELETE_ALL_SUCCESS';
export const INTRODUCTION_DELETE_ALL_FAILED = 'INTRODUCTION_DELETE_ALL_FAILED';

// Contact
export const CONTACTS_SELECT_ALL = 'CONTACTS_SELECT_ALL';
export const CONTACTS_UNSELECT_ALL = 'CONTACTS_UNSELECT_ALL';
export const CONTACT_SELECTED = 'CONTACT_SELECTED';
export const CONTACT_LOAD = 'CONTACT_LOAD';
export const CONTACT_LOAD_SUCCESS = 'CONTACT_LOAD_SUCCESS';
export const CONTACT_LOAD_FAILED = 'CONTACT_LOAD_FAILED';
export const CONTACTS_LOAD = 'CONTACTS_LOAD';
export const CONTACTS_LOAD_SUCCESS = 'CONTACTS_LOAD_SUCCESS';
export const CONTACTS_LOAD_FAILED = 'CONTACTS_LOAD_FAILED';
export const CONTACT_ADD = 'CONTACT_ADD';
export const CONTACT_ADD_SUCCESS = 'CONTACT_ADD_SUCCESS';
export const CONTACT_ADD_FAILED = 'CONTACT_ADD_FAILED';
export const CONTACT_UPDATE = 'CONTACT_UPDATE';
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS';
export const CONTACT_UPDATE_FAILED = 'CONTACT_UPDATE_FAILED';
export const CONTACT_DELETE = 'CONTACT_DELETE';
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS';
export const CONTACT_DELETE_FAILED = 'CONTACT_DELETE_FAILED';
export const CONTACT_DELETE_ALL = 'CONTACT_DELETE_ALL';
export const CONTACT_DELETE_ALL_SUCCESS = 'CONTACT_DELETE_ALL_SUCCESS';
export const CONTACT_DELETE_ALL_FAILED = 'CONTACT_DELETE_ALL_FAILED';

// About us
export const ABOUTUSES_SELECT_ALL = 'ABOUTUSES_SELECT_ALL';
export const ABOUTUSES_UNSELECT_ALL = 'ABOUTUSES_UNSELECT_ALL';
export const ABOUTUS_SELECTED = 'ABOUTUS_SELECTED';
export const ABOUTUS_LOAD = 'ABOUTUS_LOAD';
export const ABOUTUS_LOAD_SUCCESS = 'ABOUTUS_LOAD_SUCCESS';
export const ABOUTUS_LOAD_FAILED = 'ABOUTUS_LOAD_FAILED';
export const ABOUTUSES_LOAD = 'ABOUTUSES_LOAD';
export const ABOUTUSES_LOAD_SUCCESS = 'ABOUTUSES_LOAD_SUCCESS';
export const ABOUTUSES_LOAD_FAILED = 'ABOUTUSES_LOAD_FAILED';
export const ABOUTUS_ADD = 'ABOUTUS_ADD';
export const ABOUTUS_ADD_SUCCESS = 'ABOUTUS_ADD_SUCCESS';
export const ABOUTUS_ADD_FAILED = 'ABOUTUS_ADD_FAILED';
export const ABOUTUS_UPDATE = 'ABOUTUS_UPDATE';
export const ABOUTUS_UPDATE_SUCCESS = 'ABOUTUS_UPDATE_SUCCESS';
export const ABOUTUS_UPDATE_FAILED = 'ABOUTUS_UPDATE_FAILED';
export const ABOUTUS_DELETE = 'ABOUTUS_DELETE';
export const ABOUTUS_DELETE_SUCCESS = 'ABOUTUS_DELETE_SUCCESS';
export const ABOUTUS_DELETE_FAILED = 'ABOUTUS_DELETE_FAILED';
export const ABOUTUS_DELETE_ALL = 'ABOUTUS_DELETE_ALL';
export const ABOUTUS_DELETE_ALL_SUCCESS = 'ABOUTUS_DELETE_ALL_SUCCESS';
export const ABOUTUS_DELETE_ALL_FAILED = 'ABOUTUS_DELETE_ALL_FAILED';

// Statistic
export const STATISTICS_SELECT_ALL = 'STATISTICS_SELECT_ALL';
export const STATISTICS_UNSELECT_ALL = 'STATISTICS_UNSELECT_ALL';
export const STATISTIC_SELECTED = 'STATISTIC_SELECTED';
export const STATISTIC_LOAD = 'STATISTIC_LOAD';
export const STATISTIC_LOAD_SUCCESS = 'STATISTIC_LOAD_SUCCESS';
export const STATISTIC_LOAD_FAILED = 'STATISTIC_LOAD_FAILED';
export const STATISTICS_LOAD = 'STATISTICS_LOAD';
export const STATISTICS_LOAD_SUCCESS = 'STATISTICS_LOAD_SUCCESS';
export const STATISTICS_LOAD_FAILED = 'STATISTICS_LOAD_FAILED';
export const STATISTIC_ADD = 'STATISTIC_ADD';
export const STATISTIC_ADD_SUCCESS = 'STATISTIC_ADD_SUCCESS';
export const STATISTIC_ADD_FAILED = 'STATISTIC_ADD_FAILED';
export const STATISTIC_UPDATE = 'STATISTIC_UPDATE';
export const STATISTIC_UPDATE_SUCCESS = 'STATISTIC_UPDATE_SUCCESS';
export const STATISTIC_UPDATE_FAILED = 'STATISTIC_UPDATE_FAILED';
export const STATISTIC_DELETE = 'STATISTIC_DELETE';
export const STATISTIC_DELETE_SUCCESS = 'STATISTIC_DELETE_SUCCESS';
export const STATISTIC_DELETE_FAILED = 'STATISTIC_DELETE_FAILED';
export const STATISTIC_DELETE_ALL = 'STATISTIC_DELETE_ALL';
export const STATISTIC_DELETE_ALL_SUCCESS = 'STATISTIC_DELETE_ALL_SUCCESS';
export const STATISTIC_DELETE_ALL_FAILED = 'STATISTIC_DELETE_ALL_FAILED';

// Menu
export const MENUS_SELECT_ALL = 'MENUS_SELECT_ALL';
export const MENUS_UNSELECT_ALL = 'MENUS_UNSELECT_ALL';
export const MENU_SELECTED = 'MENU_SELECTED';
export const MENU_LOAD = 'MENU_LOAD';
export const MENU_LOAD_SUCCESS = 'MENU_LOAD_SUCCESS';
export const MENU_LOAD_FAILED = 'MENU_LOAD_FAILED';
export const MENUS_LOAD = 'MENUS_LOAD';
export const MENUS_LOAD_SUCCESS = 'MENUS_LOAD_SUCCESS';
export const MENUS_LOAD_FAILED = 'MENUS_LOAD_FAILED';
export const MENU_ADD = 'MENU_ADD';
export const MENU_ADD_SUCCESS = 'MENU_ADD_SUCCESS';
export const MENU_ADD_FAILED = 'MENU_ADD_FAILED';
export const MENU_UPDATE = 'MENU_UPDATE';
export const MENU_UPDATE_SUCCESS = 'MENU_UPDATE_SUCCESS';
export const MENU_UPDATE_FAILED = 'MENU_UPDATE_FAILED';
export const MENU_DELETE = 'MENU_DELETE';
export const MENU_DELETE_SUCCESS = 'MENU_DELETE_SUCCESS';
export const MENU_DELETE_FAILED = 'MENU_DELETE_FAILED';
export const MENU_DELETE_ALL = 'MENU_DELETE_ALL';
export const MENU_DELETE_ALL_SUCCESS = 'MENU_DELETE_ALL_SUCCESS';
export const MENU_DELETE_ALL_FAILED = 'MENU_DELETE_ALL_FAILED';