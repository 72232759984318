import {
	ALBUMS_SELECT_ALL,
	ALBUMS_UNSELECT_ALL,
	ALBUM_SELECTED,
	ALBUMS_LOAD,
	ALBUMS_LOAD_SUCCESS,
    ALBUMS_LOAD_FAILED,
    ALBUM_LOAD,
	ALBUM_LOAD_SUCCESS,
	ALBUM_LOAD_FAILED,
	ALBUM_ADD,
	ALBUM_ADD_SUCCESS,
	ALBUM_ADD_FAILED,
	ALBUM_UPDATE,
	ALBUM_UPDATE_SUCCESS,
	ALBUM_UPDATE_FAILED,
	ALBUM_DELETE,
	ALBUM_DELETE_SUCCESS,
	ALBUM_DELETE_FAILED,
	ALBUM_DELETE_ALL,
	ALBUM_DELETE_ALL_SUCCESS,
	ALBUM_DELETE_ALL_FAILED
} from '../../constants/ActionTypes';

export const selectAllAlbums = () => {
	return {
		type: ALBUMS_SELECT_ALL
	}
}

export const unselectAllAlbums = () => {
	return {
		type: ALBUMS_UNSELECT_ALL
	}
}

export const selectAlbum = data => {
	return {
		type: ALBUM_SELECTED,
		payload: data
	}
}

export const loadAlbums = (data) => {
	return {
		type: ALBUMS_LOAD,
		payload: data
	};
}


export const loadAlbumsSuccess = (data) => {
	return {
        type: ALBUMS_LOAD_SUCCESS,
        payload: data
	};
}


export const loadAlbumsFailed = () => {
	return {
		type: ALBUMS_LOAD_FAILED
	};
}


export const loadAlbum = () => {
	return {
		type: ALBUM_LOAD
	};
}


export const loadAlbumSuccess = (data) => {
	return {
		type: ALBUM_LOAD_SUCCESS,
		payload: data
	};
}


export const loadAlbumFailed = () => {
	return {
		type: ALBUM_LOAD_FAILED
	};
}


export const addAlbum = (data) => {
	return {
		type: ALBUM_ADD,
		payload: data
	};
}


export const addAlbumSuccess = (filter) => {
	return {
		type: ALBUM_ADD_SUCCESS,
		message: 'Album added successfully.',
		payload: filter
	};
}


export const addAlbumFailed = () => {
	return {
		type: ALBUM_ADD_FAILED
	};
}


export const updateAlbum = (data) => {
	return {
		type: ALBUM_UPDATE,
		payload: data
	};
}


export const updateAlbumSuccess = (data) => {
	return {
		type: ALBUM_UPDATE_SUCCESS,
		payload: data
	};
}


export const updateAlbumFailed = (data) => {
	return {
		type: ALBUM_UPDATE_FAILED,
		payload: data
	};
}


export const deleteAlbum = (data) => {
	return {
		type: ALBUM_DELETE,
		payload: data
	};
}


export const deleteAlbumSuccess = () => {
	return {
		type: ALBUM_DELETE_SUCCESS,
		message: 'Album deleted successfully.'
	};
}


export const deleteAlbumFailed = () => {
	return {
		type: ALBUM_DELETE_FAILED
	};
}

export const deleteAllAlbums = (data) => {
	return {
		type: ALBUM_DELETE_ALL,
		payload: data
	};
}


export const deleteAllAlbumsSuccess = () => {
	return {
		type: ALBUM_DELETE_ALL_SUCCESS,
		message: 'Album all deleted successfully.'
	};
}


export const deleteAllAlbumsFailed = () => {
	return {
		type: ALBUM_DELETE_ALL_FAILED
	};
}

